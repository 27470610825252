import CustomModal from '@components/CustomModal';
import DiscrepancyList from '@components/DiscrepancyList';
import GridListSubheader from '@components/GridListSubheader';
import DeleteIntegrationModal from '@components/modals/DeleteIntegrationModal';
import GenerateStructureIntegrationModal from '@components/modals/GenerateStructureModal';
import WolfButton from '@components/ui/WolfButton';
import { graphQlClient } from '@config/graphqlClient';
import { getTokenLocalStorage, getTokenSessionStorage } from '@config/storage';
import DiscrepanciesGraphQL from '@graphql/discrepancy.queries';
import IntegrationsGraphQL from '@graphql/integration.queries';
import { useLoader } from '@hooks/useLoader';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import {
  Discrepancy,
  getActiveDiscrepanciesRequest,
  getDiscrepanciesRequest,
  updateDiscrepancyRequest,
} from '@models/discrepancy.model';
import {
  deleteIntegrationRequest,
  getIntegrationRequest,
  Integration,
  IntegrationProvider,
  IntegrationStatus,
  IntegrationType,
} from '@models/integration.model';
import { UserRoles } from '@models/user.model';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { RootState } from '@redux/store';
import { useEffect, useState } from 'react';

const IntegrationList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setLoading } = useLoader();
  //TODO: Get all possible integrations from backend

  const { isImpersonating } = useAppSelector((state: RootState) => state.impersonate);
  const { rememberMe } = useAppSelector((state: RootState) => state.user);
  const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();
  const possibleIntegrations: Integration[] = [
    {
      integrationType: IntegrationType.CALENDAR,
      integrationProvider: IntegrationProvider.GOOGLE,
      description: 'Sync your calendar with your account',
      status: IntegrationStatus.NOT_ACTIVATED,
      link: process.env.REACT_APP_BACKEND_URL + 'auth/linkCalendar?token=' + token,
    },
    {
      integrationType: IntegrationType.STORAGE,
      integrationProvider: IntegrationProvider.GOOGLE,
      description: 'Sync your storage with your account with Google Drive',
      status: IntegrationStatus.NOT_ACTIVATED,
      link: process.env.REACT_APP_BACKEND_URL + 'auth/' + 'linkDrive?token=' + token,
    },
  ];

  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [generatingStructure, setGeneratingStructure] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>();
  const [refresh, setRefresh] = useState(false);

  const [discrepancies, setDiscrepancies] = useState<Discrepancy[]>([]);

  const { role } = useAppSelector((state: RootState) => state.user);

  const localeIntegrations = useTranslation('integrations');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');

  useEffect(() => {
    const handleFocus = () => {
      if (refresh) {
        getIntegrations();
        getDiscrepancies();
        setRefresh(false);
      }
    };

    getIntegrations();
    getDiscrepancies();
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleManualRecheck = async () => {
    if (isImpersonating) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['whileImpersonatingError'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    try {
      await graphQlClient.request(DiscrepanciesGraphQL.queries.recheckDiscrepancies);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const getIntegrations = async () => {
    try {
      setLoading(true);
      const data: getIntegrationRequest = await graphQlClient.request(IntegrationsGraphQL.queries.getIntegrations);
      setIntegrations(
        possibleIntegrations.map((possible) => {
          const matchingInt = data?.getIntegrations.find(
            (existing) =>
              possible.integrationType === existing.integrationType &&
              possible.integrationProvider === existing.integrationProvider,
          );

          if (matchingInt) {
            return {
              ...possible,
              status: matchingInt.status,
              integrationId: matchingInt.integrationId,
            };
          } else {
            return possible;
          }
        }),
      );
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const getDiscrepancies = async (showInactive?: boolean) => {
    try {
      if (showInactive) {
        const data: getDiscrepanciesRequest = await graphQlClient.request(
          DiscrepanciesGraphQL.queries.getDiscrepancies,
        );
        if (data?.getDiscrepancies) {
          setDiscrepancies(data.getDiscrepancies);
        }
      } else {
        const data: getActiveDiscrepanciesRequest = await graphQlClient.request(
          DiscrepanciesGraphQL.queries.getActiveDiscrepancies,
        );
        if (data?.getActiveDiscrepancies) {
          setDiscrepancies(data.getActiveDiscrepancies);
        }
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleShowInactiveDiscrepancies = (show: boolean) => {
    getDiscrepancies(show);
  };

  const deleteIntegration = async (id: string) => {
    try {
      const data: deleteIntegrationRequest = await graphQlClient.request(
        IntegrationsGraphQL.mutations.deleteIntegration,
        { id },
      );
      if (data?.deleteIntegration) {
        getIntegrations();
        dispatch(
          appendActionMessage({
            message: localeActionMessages['integrationDeletedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const generateStructure = async (integration: Integration | undefined) => {
    if (!integration) {
      return;
    }
    setGeneratingStructure(true);

    const variables = {
      integrationType: integration.integrationType,
      integrationProvider: integration.integrationProvider,
      integrationId: integration.integrationId,
    };

    try {
      await graphQlClient.request(IntegrationsGraphQL.mutations.generateStructure, {
        integration: variables,
      });
      setGeneratingStructure(false);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const updateDiscrepancy = async (
    selected: string,
    discrepancy: Discrepancy,
    ignoreType?: string,
    ignoreDate?: string,
  ) => {
    if (isImpersonating) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['whileImpersonatingError'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    if (ignoreType === 'custom' && !ignoreDate) {
      return;
    }
    if (selected === 'IGNORE') {
      let today = new Date();
      let newDate = new Date(today);
      switch (ignoreType) {
        case 'day':
          newDate.setDate(today.getDate() + 1);
          ignoreDate = newDate.toISOString();
          break;
        case 'month':
          newDate.setMonth(today.getMonth() + 1);
          ignoreDate = newDate.toISOString();
          break;
        case 'custom':
          ignoreDate = new Date(ignoreDate!).toISOString();
          break;
        case 'permanently':
          ignoreDate = '-1';
          break;
      }
    }

    const variables = {
      discrepancyId: discrepancy.discrepancyId,
      // integrationType: discrepancy.integrationType,
      // entity: discrepancy.discrepancyData?.entity,
      // entityId: discrepancy.discrepancyData?.entityId,
      action: selected,
      ignoreUntil: ignoreDate,
    };
    try {
      const data: updateDiscrepancyRequest = await graphQlClient.request(
        DiscrepanciesGraphQL.mutations.updateDiscrepancy,
        { discrepancy: variables },
      );
      if (data?.updateDiscrepancy) {
        dispatch(
          appendActionMessage({
            message: localeActionMessages['discrepancyUpdatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleShowDeleteModal = (integration: Integration) => {
    if (isImpersonating) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['whileImpersonatingError'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    setSelectedIntegration(integration);
    setShowDeleteModal(true);
  };

  const handleShowGenerateModal = (integration: Integration) => {
    if (isImpersonating) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['whileImpersonatingError'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    setSelectedIntegration(integration);
    setShowGenerateModal(true);
  };

  const cancelModal = () => {
    setShowDeleteModal(false);
    setShowGenerateModal(false);
    setSelectedIntegration(undefined);
  };

  const handleConfirmDelete = () => {
    deleteIntegration(selectedIntegration?.integrationId || '');
    cancelModal();
  };

  const handleConfirmStructureGenerate = () => {
    generateStructure(selectedIntegration);
    cancelModal();
  };

  const refreshOnFocus = () => {
    setRefresh(true);
  };

  return (
    <Box className="w-full">
      <Box className="sticky top-0 z-30 bg-backgroundUI">
        <GridListSubheader
          title={localeIntegrations['title']}
          buttonText={null}
          isGridView={false}
          onToggleView={() => {}}
          onDrawerOpen={() => {}}
          icon="user"
          showFavGrid={false}
        />
      </Box>{' '}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{localeIntegrations['type']}</TableCell>
              <TableCell align="center">{localeIntegrations['provider']}</TableCell>
              <TableCell align="center">{localeIntegrations['status']}</TableCell>
              <TableCell align="center">{localeCommon['action']}</TableCell>
              <TableCell align="center">{localeCommon['delete']}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations.map((integration, index: number) => (
              <TableRow key={index}>
                <TableCell align="center">{integration.integrationType}</TableCell>
                <TableCell align="center">{integration.integrationProvider}</TableCell>
                <TableCell align="center">{integration.status}</TableCell>
                <TableCell align="center">
                  {integration.link &&
                    !(
                      integration.status === IntegrationStatus.ENABLED ||
                      integration.status === IntegrationStatus.GENERATED
                    ) &&
                    (isImpersonating ? (
                      <Typography>{localeActionMessages['whileImpersonatingError']}</Typography>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        href={integration.link}
                        onClick={refreshOnFocus}
                        target="_blank">
                        {localeIntegrations['clickToEnable']}
                      </Button>
                    ))}
                  {integration.status === IntegrationStatus.ENABLED &&
                    (isImpersonating ? (
                      <Typography>{localeActionMessages['whileImpersonatingError']}</Typography>
                    ) : (
                      <Tooltip title={localeIntegrations['generateTooltip']}>
                        <Button
                          variant="contained"
                          color="primary"
                          href=""
                          onClick={() => handleShowGenerateModal(integration)}
                          target="_blank"
                          className="ml-3"
                          disabled={generatingStructure}>
                          {localeIntegrations['clickToGenerateStructure']}
                        </Button>
                      </Tooltip>
                    ))}
                  {integration.status === IntegrationStatus.GENERATED && (
                    <Button className="bg-green-500" variant="contained">
                      {localeIntegrations['enabledGenerated']}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center">
                  {(integration.status === IntegrationStatus.ENABLED ||
                    integration.status === IntegrationStatus.GENERATED) &&
                    role !== UserRoles.USER && (
                      <Button variant="contained" color="error" onClick={() => handleShowDeleteModal(integration)}>
                        {localeCommon['delete']}
                      </Button>
                    )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="flex flex-wrap items-center justify-between my-4">
        <Typography variant="h4">{localeIntegrations['checksTitle']}</Typography>
        <Box>
          <WolfButton color="primary" variant="outlined" onClick={handleManualRecheck}>
            {localeIntegrations['recheckDiscrepancies']}
          </WolfButton>
        </Box>
      </Box>
      {discrepancies && (
        <DiscrepancyList
          discrepancies={discrepancies}
          handleOptionSelected={updateDiscrepancy}
          handleShowInactive={handleShowInactiveDiscrepancies}
        />
      )}
      {selectedIntegration && (
        <>
          <CustomModal
            showButtons={true}
            isOpen={showDeleteModal}
            onClose={cancelModal}
            onConfirm={handleConfirmDelete}
            maxWidth="600px"
            title={localeIntegrations['confirmDelete']}
            customConfirmColor="error">
            <DeleteIntegrationModal integration={selectedIntegration} />
          </CustomModal>

          <CustomModal
            showButtons={true}
            title={localeIntegrations['confirmGenerate']}
            isOpen={showGenerateModal}
            maxWidth="600px"
            onClose={cancelModal}
            onConfirm={handleConfirmStructureGenerate}>
            <GenerateStructureIntegrationModal integration={selectedIntegration} />
          </CustomModal>
        </>
      )}
    </Box>
  );
};

export default IntegrationList;
