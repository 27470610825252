import ProfilePicture from '@components/ProfilePicture';
import WolfButton from '@components/ui/WolfButton';
import { EditIcon24 } from '@components/ui/WolfIcons';
import WolfInput from '@components/ui/WolfInput';
import WolfTooltip from '@components/ui/WolfTooltip';
import { graphQlClient, graphQlUpload } from '@config/graphqlClient';
import { getTokenLocalStorage, getTokenSessionStorage } from '@config/storage';
import UserGraphQL from '@graphql/user.queries';
import { useLoader } from '@hooks/useLoader';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { Organization } from '@models/organization.model';
import { getSelfWithOrganizationRequest, updateOwnUserRequest, User, UserLocale, UserRoles } from '@models/user.model';
import { ArrowForward, Save, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setLocale } from '@redux/reducers/locale.reducer';
import { setUserDetails } from '@redux/reducers/user.reducer';
import {
  permanentlyDisableWolfie,
  permanentlyReenableWolfie,
  setWolfieShowSuggestions,
  setWolfieSpeaking,
  setWolfieVideoMode,
} from '@redux/reducers/wolfie.reducer';
import { RootState } from '@redux/store';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const SettingsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState<User>({});
  const [organizationData, setOrganizationData] = useState<Organization | null>(null);
  const [saving, setSaving] = useState(false);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [userLocale, setUserLocale] = useState<UserLocale>(UserLocale.EN);
  const [showPassword, setShowPassword] = useState(false);
  const { activeLocale } = useAppSelector((state: RootState) => state.locale);

  const localeActionMessages = useTranslation('actionMessages');
  const localeCommon = useTranslation('common');
  const localeSettings = useTranslation('settings');

  const { setLoading } = useLoader();
  const { rememberMe, role } = useAppSelector((state: RootState) => state.user);
  const {
    permanentlyDisabled,
    showSuggestions,
    speaking: wolfieSpeaking,
    videoMode: wolfieVideoMode,
    messages: wolfieMessages,
  } = useAppSelector((state: RootState) => state.wolfie);

  useEffect(() => {
    getSelf();
  }, []);

  const getSelf = async () => {
    try {
      setLoading(true);
      const data: getSelfWithOrganizationRequest = await graphQlClient.request(
        UserGraphQL.queries.getSelfWithOrganization,
      );
      const user = data.getSelfWithOrganization.user;
      const organization = data.getSelfWithOrganization.organization;

      setUserData(user);
      setOrganizationData(organization);
      dispatch(setLocale(UserLocale.EN));
      setUserLocale(UserLocale.EN);
      setFirstName(user.firstName || '');
      setLastName(user.lastName || '');
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    setSaving(true);

    try {
      // Update profile picture
      if (avatar && imagePreview) {
        const data = await graphQlUpload(avatar, 'user', UserGraphQL.mutations.updateOwnUser);
        setUserData({ ...userData, avatar: data.updateOwnUser.avatar });
        dispatch(
          appendActionMessage({ message: localeActionMessages['avatarUpdatedSuccessfully'], type: SnackType.SUCCESS }),
        );
        dispatch(setUserDetails({ ...userData, avatar: data.updateOwnUser.avatar }));
        setAvatar(null);
      }

      // Update user details if changed
      if (firstName !== userData.firstName || lastName !== userData.lastName || activeLocale !== userLocale) {
        const updateUser: updateOwnUserRequest = await graphQlClient.request(UserGraphQL.mutations.updateOwnUser, {
          user: { firstName, lastName, locale: activeLocale },
        });
        if (updateUser.updateOwnUser) {
          dispatch(
            appendActionMessage({
              message: localeActionMessages['profileUpdatedSuccessfully'],
              type: SnackType.SUCCESS,
            }),
          );
          setUserData({
            ...userData,
            firstName: updateUser.updateOwnUser.firstName,
            lastName: updateUser.updateOwnUser.lastName,
            locale: updateUser.updateOwnUser.locale,
          });
          dispatch(
            setUserDetails({
              ...userData,
              firstName: updateUser.updateOwnUser.firstName,
              lastName: updateUser.updateOwnUser.lastName,
              locale: updateUser.updateOwnUser.locale,
            }),
          );
        }
      }
      // Update password
      if (oldPassword && newPassword && confirmNewPassword) {
        if (newPassword !== confirmNewPassword) {
          throw new Error('New passwords do not match');
        }

        const url =
          (process.env.REACT_APP_BACKEND_URL + 'auth/' || process.env.REACT_APP_PUBLIC_AUTH_URL) + 'updatePassword';
        const payload = {
          previousPassword: oldPassword,
          newPassword,
        };
        const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();

        if (url && token) {
          const auth = 'Bearer ' + token;
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: auth,
            },
            body: JSON.stringify(payload),
          });
          const data = await response.json();
          if (data.confirmation && data.confirmation === '200') {
            dispatch(
              appendActionMessage({
                message: localeActionMessages['passwordUpdatedSuccessfully'],
                type: SnackType.SUCCESS,
              }),
            );
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
          } else {
            throw new Error(data.errorMessage || data.error);
          }
        }
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setSaving(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setFirstName(userData.firstName || '');
    setLastName(userData.lastName || '');
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setFirstName(userData.firstName || '');
    setLastName(userData.lastName || '');
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((show) => !show);
  };

  const toggleDisabled = () => {
    if (permanentlyDisabled) {
      dispatch(permanentlyReenableWolfie());
    } else {
      dispatch(permanentlyDisableWolfie());
    }
  };

  const toggleShowSuggestions = () => {
    dispatch(setWolfieShowSuggestions(!showSuggestions));
  };

  const toggleEnableVoiceMode = () => {
    if (wolfieVideoMode) {
      dispatch(setWolfieVideoMode(false));
    }
    if (wolfieSpeaking) {
      speechSynthesis.cancel();
    } else {
      const lastAssistantMessage = [...wolfieMessages].reverse().find((message) => message.role === 'assistant');

      const speech = new SpeechSynthesisUtterance(lastAssistantMessage?.content);
      speech.lang = 'en-US';
      speechSynthesis.speak(speech);
    }
    dispatch(setWolfieSpeaking(!wolfieSpeaking));
  };

  const toggleEnableAvatarMode = () => {
    if (wolfieSpeaking) {
      speechSynthesis.cancel();
      dispatch(setWolfieSpeaking(false));
    }
    dispatch(setWolfieVideoMode(!wolfieVideoMode));
  };

  return (
    <Box className="flex flex-wrap w-full max-w-screen-md p-4 justify-center mx-auto">
      <Box className="w-full">
        <Typography variant="h2" className="!my-8 text-center sm:text-left">
          {localeSettings['profile']}
        </Typography>
        <Box className="flex flex-col sm:flex-row justify-between items-center sm:items-start gap-4">
          <Box className="w-full sm:w-auto order-2 sm:order-1">
            {isEditing ? (
              <Box className="flex flex-col sm:flex-row items-center gap-2">
                <WolfInput
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  label={localeCommon['firstName']}
                  boxFullWidth={true}
                />
                <WolfInput
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  label={localeCommon['lastName']}
                  className="mt-2 sm:mt-0 sm:ml-2"
                  boxFullWidth={true}
                />
                <IconButton className="mt-2 sm:mt-0 sm:ml-2" onClick={handleCancelClick}>
                  <EditIcon24 />
                </IconButton>
              </Box>
            ) : (
              <Box className="w-full flex flex-col sm:flex-row items-center text-center sm:text-left">
                <Typography variant="h4semibold" className="!mr-2">
                  {userData.firstName + ' ' + userData.lastName}
                </Typography>
                {role !== UserRoles.USER && (
                  <WolfTooltip title={localeSettings['editName']}>
                    <span>
                      <IconButton className="mt-2 sm:mt-0 sm:ml-2 !p-1" onClick={handleEditClick}>
                        <EditIcon24 />
                      </IconButton>
                    </span>
                  </WolfTooltip>
                )}
              </Box>
            )}
            <Box className="my-3 text-center sm:text-left">
              <Typography variant="h4" gutterBottom>
                {userData.email}
              </Typography>
            </Box>
            <Box className="flex flex-col sm:flex-row items-center">
              <Typography variant="h4" className="!mr-2 text-center sm:text-left">
                {localeCommon['organization']}:
                <Typography variant="h4semibold" gutterBottom>
                  {organizationData?.name}
                </Typography>
              </Typography>
              {role !== UserRoles.USER && (
                <WolfTooltip title={localeSettings['goToOrganization']}>
                  <span>
                    <IconButton
                      color="primary"
                      onClick={() => history.push('organization/' + organizationData?.organizationId)}>
                      <ArrowForward />
                    </IconButton>
                  </span>
                </WolfTooltip>
              )}
            </Box>
          </Box>

          <Box className="order-1 sm:order-2">
            <ProfilePicture
              src={imagePreview || userData?.avatar || ''}
              name={userData.firstName}
              handleImageChange={handleImageChange}
            />
          </Box>
        </Box>

        {userData.loginMethod === 'cognito' && (
          <>
            <Typography variant="h4" className="!my-3 text-center sm:text-left">
              {localeSettings['changePassword']}:
            </Typography>
            <Box className="flex flex-col sm:flex-row w-full justify-center text-center gap-4">
              <WolfInput
                label={localeSettings['oldPassword']}
                placeholder={localeSettings['oldPassword']}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                showIcon={true}
                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                iconAction={handleTogglePasswordVisibility}
                boxFullWidth={true}
              />
              <WolfInput
                label={localeSettings['newPassword']}
                placeholder={localeSettings['newPassword']}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                showIcon={true}
                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                iconAction={handleTogglePasswordVisibility}
                boxFullWidth={true}
              />
              <WolfInput
                label={localeSettings['confirmNewPassword']}
                placeholder={localeSettings['confirmNewPassword']}
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                showIcon={true}
                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                iconAction={handleTogglePasswordVisibility}
                type={showPassword ? 'text' : 'password'}
                boxFullWidth={true}
              />
            </Box>
            <Box className="w-full flex justify-center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={saving}
                startIcon={saving ? <CircularProgress size={20} /> : <Save />}
                sx={{ marginTop: 2 }}>
                {saving ? `${localeCommon['saving']}...` : localeCommon['save']}
              </Button>
            </Box>
          </>
        )}

        <Typography variant="h2" className="!my-8 text-center sm:text-left">
          {localeSettings['configuration']}
        </Typography>

        <Box className="my-3">
          <Box className="flex flex-col sm:flex-row items-center justify-between gap-2">
            <Typography variant="h4" marginBottom={0} marginRight={2} className="text-center sm:text-left">
              {localeSettings['wolfieStatus']}:
              <Typography variant="h4semibold" gutterBottom>
                {permanentlyDisabled ? localeCommon['disabled'] : localeCommon['enabled']}
              </Typography>
            </Typography>
            <WolfButton color="primary" onClick={toggleDisabled}>
              {permanentlyDisabled
                ? localeCommon['enable'] + ' ' + localeCommon['wolfie']
                : localeCommon['disable'] + ' ' + localeCommon['wolfie']}
            </WolfButton>
          </Box>
        </Box>
        <Box className="my-3">
          <Box className="flex flex-col sm:flex-row items-center justify-between gap-2">
            <Typography variant="h4" marginBottom={0} marginRight={2} className="text-center sm:text-left">
              {localeSettings['wolfieSuggestions']}:
              <Typography variant="h4semibold" gutterBottom>
                {!showSuggestions ? localeCommon['disabled'] : localeCommon['enabled']}
              </Typography>
            </Typography>
            <WolfButton color="primary" onClick={toggleShowSuggestions}>
              {!showSuggestions
                ? localeCommon['enable'] + ' ' + localeCommon['suggestions']
                : localeCommon['disable'] + ' ' + localeCommon['suggestions']}
            </WolfButton>
          </Box>
        </Box>
        {(role === UserRoles.SUPER_ADMIN || role === UserRoles.OWNER) && (
          <Box className="my-3">
            <Box className="flex flex-col sm:flex-row items-center justify-between gap-2">
              <Typography variant="h4" marginBottom={0} marginRight={2} className="text-center sm:text-left">
                {localeSettings['wolfieVoiceMode']}:
                <Typography variant="h4semibold" gutterBottom>
                  {!wolfieSpeaking ? localeCommon['disabled'] : localeCommon['enabled']}
                </Typography>
              </Typography>
              <WolfButton color="primary" onClick={toggleEnableVoiceMode}>
                {!wolfieSpeaking
                  ? localeCommon['enable'] + ' ' + localeSettings['voiceMode']
                  : localeCommon['disable'] + ' ' + localeSettings['voiceMode']}
              </WolfButton>
            </Box>
          </Box>
        )}
        {(role === UserRoles.SUPER_ADMIN || role === UserRoles.OWNER) && (
          <Box className="my-3">
            <Box className="flex flex-col sm:flex-row items-center justify-between gap-2">
              <Typography variant="h4" marginBottom={0} marginRight={2} className="text-center sm:text-left">
                {localeSettings['wolfieAvatarMode']}:
                <Typography variant="h4semibold" gutterBottom>
                  {!wolfieVideoMode ? localeCommon['disabled'] : localeCommon['enabled']}
                </Typography>
              </Typography>
              <WolfButton color="primary" onClick={toggleEnableAvatarMode}>
                {!wolfieVideoMode
                  ? localeCommon['enable'] + ' ' + localeSettings['avatarMode']
                  : localeCommon['disable'] + ' ' + localeSettings['avatarMode']}
              </WolfButton>
            </Box>
          </Box>
        )}
        {/* CHECK: Language picker hidden until locales are re-implemented */}
        {/* <Box className="my-3 flex justify-center">
          <LanguagePicker />
        </Box> */}
      </Box>
    </Box>
  );
};

export default SettingsPage;
