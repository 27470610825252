import CustomModal from '@components/CustomModal';
import DocumentCategories from '@components/DocumentCategories';
import GridListSubheader from '@components/GridListSubheader';
import WolfProgressBar from '@components/ui/WolfProgressBar';
import UploadDocumentModal from '@components/UploadDocumentModal';
import { graphQlClient, graphQlUpload } from '@config/graphqlClient';
import DocumentCategoriesGraphQL from '@graphql/document.category.queries';
import DocumentsGraphQL from '@graphql/document.queries';
import { useDebounce } from '@hooks/useDebounce';
import { useLoader } from '@hooks/useLoader';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { getDocumentCategoriesRequest } from '@models/document.categories.model';
import { deleteDocumentRequest, Document, getDocumentsRequest } from '@models/document.model';
import { UserRoles } from '@models/user.model';
import { Box, Typography } from '@mui/material';
import DocumentList from '@pages/documents/list';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { RootState } from '@redux/store';
import { useCallback, useEffect, useRef, useState } from 'react';

interface DocumentsPageProps {
  isModal?: boolean;
  initialFile?: File | null;
  updatedFile?: (data: any) => void;
}

const DocumentsPage: React.FC<DocumentsPageProps> = ({ isModal = false, initialFile, updatedFile }) => {
  // common
  const { setLoading } = useLoader();
  const dispatch = useAppDispatch();
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');
  const localeDocuments = useTranslation('documents');

  const { role } = useAppSelector((state: RootState) => state.user);

  // categories
  const [categories, setCategories] = useState<any[]>([]);

  // list
  const [documents, setDocuments] = useState<Document[]>([]);
  const [count, setCount] = useState<number>(0);
  // search
  const [search, setSearch] = useState<string>('');
  const debouncedSearchValue = useDebounce(search, 500);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  //filter
  const [filter, setFilter] = useState<string>('Active');
  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };
  //categoryFilter
  const [categoryFilter, setCategoryFilter] = useState<string>('');
  const handleCategoryFilterChange = (categoryFilter: string) => {
    setCategoryFilter(categoryFilter);
  };
  // sort
  const [orderBy, setOrderBy] = useState<string | null>('name');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // pagination
  const [page, setPage] = useState<number>(0);
  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(event.target.value as unknown as number);
    setPage(0);
  };

  // upload document
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showFile, setShowFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>('');
  const [fileName, setFileName] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [showUploadDocument, setShowUploadDocument] = useState(false);

  // initialize
  useEffect(() => {
    if (initialFile) {
      setShowFile(initialFile);
      setFileName(initialFile.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(initialFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFile]);

  const getDocuments = useCallback(async () => {
    try {
      setLoading(true);
      const data: getDocumentsRequest = await graphQlClient.request(DocumentsGraphQL.queries.getDocuments, {
        documents: {
          page,
          limit: rowsPerPage,
          search: debouncedSearchValue,
          filter,
          categoryFilter,
          orderBy,
          orderDirection,
        },
      });
      setDocuments(data?.getDocuments?.documents || []);
      setCount(data?.getDocuments?.count || 0);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    filter,
    categoryFilter,
    localeCommon,
    orderBy,
    orderDirection,
    page,
    rowsPerPage,
    debouncedSearchValue,
  ]);

  const getCategories = async () => {
    const data: getDocumentCategoriesRequest = await graphQlClient.request(
      DocumentCategoriesGraphQL.queries.getDocumentCategories,
    );
    try {
      setCategories(data.getDocumentCategories);
    } catch (e: any) {
      setCategories([]);
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  // initialize
  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, debouncedSearchValue, filter, categoryFilter, orderBy, orderDirection, getDocuments]);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalClose = (): void => {
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement).value = '';
    }
    setShowFile(null);
    setShowUploadDocument(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setShowFile(file);
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value);
  };

  const uploadFile = async (category?: string, owner?: string) => {
    setSaving(true);
    if (showFile) {
      try {
        const data = await graphQlUpload(showFile, 'document', DocumentsGraphQL.mutations.uploadDocument, {
          name: fileName && fileName !== showFile.name ? fileName : showFile.name,
          categoryId: category || '',
          owner: owner || '',
        });
        dispatch(
          appendActionMessage({
            message: localeActionMessages['documentUpdatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        modalClose();
        getDocuments();
        updatedFile && updatedFile(data?.uploadDocument?.documentId);
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.message || e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        setSaving(false);
      }
    }
  };

  const deleteDocument = async (documentId: string) => {
    try {
      const deleteResult: deleteDocumentRequest = await graphQlClient.request(
        DocumentsGraphQL.mutations.deleteDocument,
        { id: documentId },
      );
      if (deleteResult?.deleteDocument?.affected > 0) {
        getDocuments();
        dispatch(
          appendActionMessage({
            message: localeActionMessages['documentDisabledSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleSelectDocument = (id: string) => {
    updatedFile && updatedFile(id);
    modalClose();
  };

  const refreshCategories = () => {
    getDocuments();
    getCategories();
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Box className="w-full">
      <Box className="sticky top-0 z-30 bg-backgroundUI">
        <GridListSubheader
          title={localeDocuments['title']}
          buttonText={role !== UserRoles.USER ? localeDocuments['addDocument'] : null}
          isGridView={false}
          onToggleView={() => {}}
          onDrawerOpen={handleButtonClick}
          onFileChange={handleFileChange}
          icon="document"
          showFavGrid={false}
          inputRef={inputRef}
        />
      </Box>{' '}
      {!isModal && role !== UserRoles.USER && (
        <Box>
          <Typography variant="h5">{localeDocuments['documentCategories']}</Typography>
          <Box className="my-3">
            <DocumentCategories refresh={refreshCategories} />
          </Box>
        </Box>
      )}
      <DocumentList
        handleDeleteDocument={deleteDocument}
        isModal={isModal}
        count={count}
        documents={documents}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        search={search}
        handleSearchChange={handleSearchChange}
        orderDirection={orderDirection}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        filter={filter}
        handleFilterChange={handleFilterChange}
        categoryFilter={categoryFilter}
        handleCategoryFilterChange={handleCategoryFilterChange}
        handleSelectDocument={handleSelectDocument}
        handleRefresh={getDocuments}
        categories={categories}
      />
      <CustomModal isOpen={!!showFile || showUploadDocument} onClose={modalClose} showButtons={false}>
        <UploadDocumentModal
          fileName={fileName}
          handleNameChange={handleNameChange}
          preview={preview}
          showFile={showFile}
          modalClose={modalClose}
          uploadFile={uploadFile}
          saving={saving}
        />
      </CustomModal>
      <CustomModal isOpen={saving} onClose={() => {}} hideClose={true}>
        <WolfProgressBar duration={2000} />
      </CustomModal>
    </Box>
  );
};

export default DocumentsPage;
