import CustomModal from '@components/CustomModal';
import DropdownMenu from '@components/DropdownMenu';
import DeleteDocumentModal from '@components/modals/DeleteDocumentModal';
import PreviewUpdateDocumentModal from '@components/modals/PreviewUpdateDocumentModal';
import ReplaceDocumentModal from '@components/modals/ReplaceDocumentModal';
import { TrashIcon24 } from '@components/ui/WolfIcons';
import WolfSearch from '@components/ui/WolfSearch';
import { VisuallyHiddenInput } from '@components/VisuallyHiddenInput';
import { graphQlClient, graphQlUpload } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import DocumentsGraphQL from '@graphql/document.queries';
import { useTranslation } from '@hooks/useTranslation';
import { getAreasRequest } from '@models/area.model';
import { SnackType } from '@models/common.model';
import { DocumentCategory } from '@models/document.categories.model';
import { Document, DocumentStatus } from '@models/document.model';
import { UserRoles } from '@models/user.model';
import { OndemandVideoOutlined } from '@mui/icons-material';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { RootState } from '@redux/store';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// TODO: With some work this could become generic if we replace documents for a generic type

interface DocumentListProps {
  isModal: boolean;
  // deleteDocument
  handleDeleteDocument: (documentId: string) => void;
  handleSelectDocument: (documentId: string) => void;
  // common
  count: number;
  documents: Document[];
  // search
  search: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // filter
  filter: string;
  handleFilterChange: (filter: string) => void;
  // categoryFilter
  categoryFilter: string;
  handleCategoryFilterChange: (categoryFilter: string) => void;
  // order
  orderDirection: 'asc' | 'desc';
  orderBy: string | null;
  handleRequestSort: (property: string) => void;
  // pagination
  page: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRefresh: () => void;
  categories: DocumentCategory[];
}

const DocumentList: React.FC<DocumentListProps> = ({
  isModal,
  count,
  documents,
  search,
  handleSearchChange,
  filter,
  handleFilterChange,
  categoryFilter,
  handleCategoryFilterChange,
  orderBy,
  orderDirection,
  handleRequestSort,
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleDeleteDocument,
  handleSelectDocument,
  handleRefresh,
  categories,
}) => {
  const [areas, setAreas] = useState<any[]>([]);
  const [categoryFilterItems, setCategoryFilterItems] = useState<any[]>([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<Document | undefined>(undefined);
  const [showReplaceModal, setShowReplaceModal] = useState<boolean>(false);
  const [showFile, setShowFile] = useState<Document | null>(null);
  const [updatedFile, setUpdatedFile] = useState<Partial<Document> | null>(null);
  const [refreshedFile, setRefreshedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>('');
  const [saving, setSaving] = useState<boolean>(false);

  const { role } = useAppSelector((state: RootState) => state.user);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const localeCommon = useTranslation('common');
  const localeDocuments = useTranslation('documents');
  const localeActionMessages = useTranslation('actionMessages');

  const toggleConfirmDeleteModal = (document?: Document) => {
    setShowConfirmDeleteModal(document);
  };

  const closeDeleteModal = () => {
    setShowConfirmDeleteModal(undefined);
  };

  const toggleReplaceModal = (document?: Document) => {
    setShowReplaceModal(!showReplaceModal);
    if (document) {
      setUpdatedFile(document);
    } else {
      setUpdatedFile(null);
    }
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement).value = '';
    }
  };

  const deleteDocument = () => {
    if (showConfirmDeleteModal) {
      handleDeleteDocument(showConfirmDeleteModal.documentId);
    }
    setShowConfirmDeleteModal(undefined);
  };

  const previewFile = (document: Document) => {
    setShowFile(document);
  };

  useEffect(() => {
    getAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updatedCategoryFilterItems = [
      ...categories.map((category) => ({
        value: category.documentCategoryId,
        label: category.name,
      })),
      { value: '', label: localeCommon['all'] },
    ];
    setCategoryFilterItems(updatedCategoryFilterItems);
  }, [categories, localeCommon]);

  const getAreas = async () => {
    try {
      const data: getAreasRequest = await graphQlClient.request(AreasGraphQL.queries.getAreas);
      setAreas(data.getAreas);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, document: Document) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setRefreshedFile(file);
      setUpdatedFile({ ...updatedFile, name: file.name });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
      toggleReplaceModal(document);
    }
  };

  const uploadUpdatedFile = async () => {
    if (refreshedFile) {
      setSaving(true);
      try {
        const data = await graphQlUpload(refreshedFile, 'document', DocumentsGraphQL.mutations.updateDocumentContent, {
          documentId: updatedFile?.documentId,
        });
        if (data?.updateDocumentContent?.affected > 0) {
          dispatch(
            appendActionMessage({
              message: localeActionMessages['documentUpdatedSuccessfully'],
              type: SnackType.SUCCESS,
            }),
          );
          toggleReplaceModal();
          handleRefresh();
          setUpdatedFile(null);
        } else {
          dispatch(
            appendActionMessage({
              message: localeActionMessages['requestError'],
              type: SnackType.ERROR,
            }),
          );
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.message || e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        setSaving(false);
      }
    }
  };

  const previewUpdateModalClose = (refresh: boolean = false): void => {
    setShowFile(null);
    if (refresh) {
      handleRefresh();
    }
  };

  const sortedTableHeaderCell = ({
    label,
    property,
    align,
  }: {
    label: string;
    property: string;
    align?: 'right' | 'left' | 'center' | 'inherit' | 'justify' | undefined;
  }) => {
    return (
      <TableCell sortDirection={orderBy === property ? orderDirection : false} align={align ?? 'inherit'}>
        <TableSortLabel
          active={orderBy === property}
          direction={orderBy === property ? orderDirection : 'asc'}
          onClick={() => handleRequestSort(property)}>
          {label}
        </TableSortLabel>
      </TableCell>
    );
  };

  const getCategoryName = (categoryId: string) => {
    if (!categoryId) return '-';
    if (!categories) return '...';
    const category = categories.find((category) => category.documentCategoryId === categoryId);
    return category?.name || '';
  };

  const getOwnerName = (id: string) => {
    if (!id) return '-';
    if (!areas) return '...';
    const area = areas.find((area) => area.areaId === id);
    if (area) return area.name;
    const areaWithService = areas.find((area) => area?.services?.some((auxsvc: any) => auxsvc.serviceId === id));
    if (!areaWithService) return '-';
    const service = areaWithService.services.find((service: any) => service.serviceId === id);
    return areaWithService?.name + ' - ' + service?.name || '';
  };

  const activeFilterItems = [
    { value: 'All', label: localeCommon['all'] },
    { value: 'Active', label: localeCommon['active'] },
    { value: 'Deleted', label: localeCommon['deleted'] },
  ];

  return (
    <Box>
      <Box className="flex items-center mb-2">
        <WolfSearch value={search} onChange={handleSearchChange} />
        <DropdownMenu items={activeFilterItems} applyFilter={handleFilterChange} filter={filter} />
        <DropdownMenu items={categoryFilterItems} applyFilter={handleCategoryFilterChange} filter={categoryFilter} />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{localeCommon['show']}</TableCell>
              {sortedTableHeaderCell({ label: 'Name', property: 'name' })}
              {sortedTableHeaderCell({ label: 'Category', property: 'categoryId' })}
              {!isModal && sortedTableHeaderCell({ label: 'Owner', property: 'owner' })}
              {!isModal && sortedTableHeaderCell({ label: 'Date Created', property: 'createdAt', align: 'right' })}
              {sortedTableHeaderCell({ label: 'Last Update', property: 'lastUpdatedAt', align: 'right' })}
              {!isModal && sortedTableHeaderCell({ label: 'Status', property: 'status', align: 'right' })}
              <TableCell align="center">{localeCommon['actions']}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((row, idx) => (
              <TableRow key={`${idx}-${row.name}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  padding="checkbox"
                  sx={{ cursor: 'pointer', textAlign: 'center' }}
                  onClick={() => previewFile(row)}>
                  {row.fileType.includes('image') ? (
                    <ImageIcon />
                  ) : row.fileType.includes('video') ? (
                    <OndemandVideoOutlined />
                  ) : (
                    <InsertDriveFileIcon />
                  )}
                </TableCell>
                <TableCell component="th" scope="row" title={row.name}>
                  {row.name.slice(0, 25) + (row.name.length > 25 ? '...' : '')}
                </TableCell>
                <TableCell component="th" scope="row">
                  {getCategoryName(row.categoryId)}
                </TableCell>
                {!isModal && (
                  <TableCell component="th" scope="row">
                    {getOwnerName(row.owner)}
                  </TableCell>
                )}
                {!isModal && (
                  <TableCell align="right">{DateTime.fromISO(row.createdAt.toString()).toLocaleString()}</TableCell>
                )}
                <TableCell align="right">{DateTime.fromISO(row.lastUpdatedAt.toString()).toLocaleString()}</TableCell>
                {!isModal && <TableCell align="right">{row.status}</TableCell>}
                <TableCell align="right" sx={{ textAlign: 'center' }} width={140}>
                  <Box>
                    {isModal ? (
                      <IconButton onClick={() => handleSelectDocument(row.documentId)}>
                        <ContentPasteGoIcon color="info" sx={{ margin: 1 }} />
                      </IconButton>
                    ) : (
                      row.status === DocumentStatus.ACTIVE &&
                      role !== UserRoles.USER && (
                        <Box className="flex items-center">
                          <IconButton onClick={() => toggleConfirmDeleteModal(row)}>
                            <TrashIcon24 color="error" sx={{ margin: 1 }} />
                          </IconButton>
                          <Tooltip title={localeDocuments['replaceWithNew']} placement="top">
                            <Button component="label" tabIndex={-1}>
                              <VisuallyHiddenInput type="file" onChange={(e) => handleFileChange(e, row)} />
                              <PublishedWithChangesIcon color="primary" sx={{ margin: 1 }} />
                            </Button>
                          </Tooltip>
                        </Box>
                      )
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <CustomModal
        isOpen={!!showFile}
        onClose={previewUpdateModalClose}
        customConfirmText={localeCommon['update']}
        showButtons={false}
        title={localeDocuments['documentPreview']}>
        <PreviewUpdateDocumentModal
          showFile={showFile}
          categories={categories}
          showUpdate={!isModal && role !== UserRoles.USER}
          onClose={previewUpdateModalClose}
        />
      </CustomModal>

      {showConfirmDeleteModal && (
        <CustomModal
          isOpen={!!showConfirmDeleteModal}
          title={localeDocuments['confirmDelete']}
          customConfirmColor="error"
          maxWidth="600px"
          showButtons={true}
          onClose={closeDeleteModal}
          onConfirm={deleteDocument}>
          <DeleteDocumentModal document={showConfirmDeleteModal} />
        </CustomModal>
      )}

      {showReplaceModal && (
        <CustomModal
          isOpen={showReplaceModal}
          title={localeDocuments['replaceDocument']}
          showButtons={true}
          onClose={toggleReplaceModal}
          onConfirm={uploadUpdatedFile}
          customConfirmText={localeDocuments['upload']}
          customConfirmColor="primary">
          <ReplaceDocumentModal
            fileName={updatedFile?.name || ''}
            preview={preview}
            fileType={refreshedFile?.type || ''}
            saving={saving}
          />
        </CustomModal>
      )}
    </Box>
  );
};

export default DocumentList;
