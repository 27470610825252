import AddItemForm from '@components/drawers/AddItemForm';
import CustomDrawer from '@components/drawers/CustomDrawer';
import GridListSubheader from '@components/GridListSubheader';
import ItemWithSummaryDrag from '@components/ItemWithSummaryDrag';
import WolfButton from '@components/ui/WolfButton';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import IntegrationsGraphQL from '@graphql/integration.queries';
import OrganizationsGraphQL from '@graphql/organization.queries';
import { useLoader } from '@hooks/useLoader';
import { useTranslation } from '@hooks/useTranslation';
import useUnsavedChangesWarning from '@hooks/useUnsavedChangesWarning';
import {
  Area,
  createOrUpdateAreaRequest,
  deleteAreaRequest,
  getAreasRequest,
  reorderAreasRequest,
} from '@models/area.model';
import { DrawerType, EntityData, SnackType } from '@models/common.model';
import { createOrUpdateOrganizationRequest } from '@models/organization.model';
import { UserRoles } from '@models/user.model';
import { WolfieFTUEStatus, WolfieMode, WolfieStatus } from '@models/wolfie.model';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setOrganizationData } from '@redux/reducers/organization.reducer';
import {
  clearConfirmedWolfieElements,
  removeFromWolfieConfirmedElements,
  removeFromWolfieData,
  setWolfieContextInfo,
  setWolfieEnabled,
  setWolfieFTUEStatus,
  setWolfieMinimized,
  setWolfieShowSuggestions,
  setWolfieStatus,
  setWolfieUnableToConfirmElement,
  setWolfieVideoMode,
} from '@redux/reducers/wolfie.reducer';
import { RootState } from '@redux/store';
import { checkForIntegrations } from '@utils/checkForIntegrations';
import { notValidDrop } from '@utils/notValidDrop';
import { AREA_COLORS } from '@utils/random.color';
import { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';

const AreaList: React.FC = () => {
  const dispatch = useAppDispatch();
  const locAM = useTranslation('actionMessages');
  const localeAreas = useTranslation('areas');
  const localeCommon = useTranslation('common');
  const localeWolfie = useTranslation('wolfie');
  const history = useHistory();
  const { setLoading } = useLoader();

  const { role } = useAppSelector((state: RootState) => state.user);

  const [areas, setAreas] = useState<any[]>([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState<DrawerType>(DrawerType.ADD);
  const [entityData, setEntityData] = useState<EntityData>({
    areaId: '',
    name: '',
    description: '',
    color: '',
  });
  const [hasCalendar, setHasCalendar] = useState(false);
  const [hasStorage, setHasStorage] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isGridView, setIsGridView] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  useUnsavedChangesWarning({ showWarning: isGenerating });

  const {
    data: wolfieData,
    status: wolfieStatus,
    mode: wolfieSelectedMode,
    statusFTUE: wolfieFTUEStatus,
    confirmedElements,
    permanentlyDisabled,
    enabled: softEnabledWolfie,
  } = useAppSelector((state: RootState) => state.wolfie);
  const {
    name: organizationName,
    description: organizationDescription,
    lastProfileUpdate,
    organizationId,
    context: orgContext,
    onboardingDetails,
  } = useAppSelector((state: RootState) => state.organization);
  const enabledWolfie = process.env.REACT_APP_ENABLE_WOLFIE === 'true' && !permanentlyDisabled;

  useEffect(() => {
    getAreas();
    getIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (wolfieData && wolfieSelectedMode === WolfieMode.NO_AREAS && softEnabledWolfie) {
      handleSuggestedAreas(wolfieData);
    }
  }, [wolfieData, wolfieSelectedMode, softEnabledWolfie]);

  useEffect(() => {
    if (softEnabledWolfie && wolfieSelectedMode === WolfieMode.NO_AREAS) {
      if (wolfieStatus === WolfieStatus.RESEARCHING && confirmedElements.length > 0) {
        handleConfirmAreaById(confirmedElements[confirmedElements.length - 1]);
      }
      if (wolfieStatus === WolfieStatus.DONE && confirmedElements.length > 0) {
        multipleElementsConfirmWrapper(confirmedElements);
      }
      if (wolfieStatus === WolfieStatus.DECLINED || permanentlyDisabled) {
        setIsGenerating(false);
        setAreas(areas.filter((area: Area) => !area.isWolfieGenerated));
      }
    } else if (softEnabledWolfie && wolfieSelectedMode === WolfieMode.FTUE) {
      if (wolfieStatus === WolfieStatus.DONE && wolfieData?.description) {
        // Here we save the context data in the organization entity
        try {
          const { description, mainAreas, troublesomeProcesses } = wolfieData;
          updateOrganization(description, mainAreas, troublesomeProcesses);
        } catch (e) {
          console.log('error', e);
        }
      }
    }
    if (!softEnabledWolfie) {
      setIsGenerating(false);
    }
  }, [confirmedElements, wolfieStatus, wolfieSelectedMode, wolfieData, softEnabledWolfie, permanentlyDisabled]);

  useEffect(() => {
    if (wolfieFTUEStatus === WolfieFTUEStatus.SHOW) {
      setIsGenerating(true);
      dispatch(setWolfieEnabled(true));
      dispatch(setWolfieShowSuggestions(false));
      dispatch(setWolfieStatus(WolfieStatus.RESEARCHING));
      dispatch(setWolfieContextInfo({ name: organizationName || '' }));
      dispatch(setWolfieMinimized(false));
      dispatch(setWolfieVideoMode(true));
    }
  }, [wolfieFTUEStatus]);

  const handleToggleView = () => {
    setIsGridView(!isGridView);
  };

  const handleAreaClick = async (id: string) => {
    history.push(`/services?areaId=${id}`);
  };

  const handleIgnoreFTUE = () => {
    updateOrganizationIgnore();
    dispatch(setWolfieFTUEStatus(WolfieFTUEStatus.IGNORE));
  };

  const handleShowFTUE = () => {
    dispatch(setWolfieFTUEStatus(WolfieFTUEStatus.SHOW));
  };

  // DRAWER HANDLE *********************
  const drawerNewOpen = () => {
    setDrawerType(DrawerType.ADD);
    setShowDrawer(true);
  };

  const drawerEditOpen = (id: string) => {
    setDrawerType(DrawerType.EDIT);
    setShowDrawer(true);
    const areaToEdit = areas.find((area: Area) => area.areaId === id);
    if (areaToEdit) {
      setEntityData(areaToEdit);
    }
  };

  const drawerClose = (): void => {
    setShowDrawer(false);
  };

  const updateOrganization = async (description: string, mainAreas: any[], troublesomeProcesses: any[]) => {
    try {
      const query = OrganizationsGraphQL.mutations.updateOrganization;
      const params: any = {
        organization: {
          description,
          context: {
            mainAreas,
            troublesomeProcesses,
          },
          onboardingDetails: {
            ...onboardingDetails,
            ftueCompleted: true,
          },
          organizationId,
        },
      };
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(query, params);
      if (data?.updateOrganization?.organizationId) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestSuccess'],
            type: SnackType.SUCCESS,
          }),
        );
        dispatch(setOrganizationData(data.updateOrganization));
        const description = data?.updateOrganization?.description;
        // TODO: This is just for development purposes, remove
        dispatch(setWolfieFTUEStatus(null));
        getAreas(true);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const updateOrganizationIgnore = async () => {
    try {
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(
        OrganizationsGraphQL.mutations.updateOrganization,
        {
          organization: {
            organizationId: organizationId || '',
            lastProfileUpdate: new Date().toISOString(),
          },
        },
      );
      if (data?.updateOrganization?.organizationId) {
        dispatch(setOrganizationData(data.updateOrganization));
      }
    } catch {}
  };

  const updateOrganizationAreaCreated = async () => {
    try {
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(
        OrganizationsGraphQL.mutations.updateOrganization,
        {
          organization: {
            organizationId: organizationId || '',
            onboardingDetails: {
              ...onboardingDetails,
              areaCreated: true,
            },
          },
        },
      );
      if (data?.updateOrganization?.organizationId) {
        dispatch(setOrganizationData(data.updateOrganization));
      }
    } catch {}
  };

  // Drag and Drop
  const handleDragEnd = async (result: DropResult) => {
    if (notValidDrop(result)) return;
    const newAreas: Area[] = [...areas];
    const prev = newAreas[result.destination!.index];
    newAreas[result.destination!.index] = newAreas[result.source.index]; //Checked in notValidDrop
    newAreas[result.source.index] = prev;

    const areasIds = newAreas.map((item: Area) => item.areaId);

    try {
      const data: reorderAreasRequest = await graphQlClient.request(AreasGraphQL.mutations.reorderAreas, {
        areas: { areasIds },
      });
      if (data?.reorderAreas.messages[0] === 'Update successful') {
        setAreas(newAreas);
        dispatch(appendActionMessage({ message: locAM['areasReorderedSuccessfully'], type: SnackType.SUCCESS }));
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  // CRUD Operations (incl Drawer save new/edit)
  // TODO: Clean up try/catches and consolidate
  const getAreas = async (justCompleted: boolean = false) => {
    try {
      dispatch(setWolfieFTUEStatus(null));
      setLoading(true);
      const data: getAreasRequest = await graphQlClient.request(AreasGraphQL.queries.getAreas);
      setAreas(data.getAreas.sort((a: any, b: any) => (a.order > b.order ? 1 : -1)));

      if (data.getAreas.length === 0 && enabledWolfie) {
        // TODO Adjust criteria for dtue
        // We show FTUE if there are no areas, no profile updated and the user has not ignored it
        if (!onboardingDetails?.ftueCompleted && !(wolfieFTUEStatus === WolfieFTUEStatus.IGNORE || justCompleted)) {
          dispatch(setWolfieFTUEStatus(WolfieFTUEStatus.MESSAGE));
        } else {
          dispatch(setWolfieFTUEStatus(null));
          setIsGenerating(true);
          dispatch(setWolfieEnabled(true));
          dispatch(setWolfieShowSuggestions(true));
          dispatch(setWolfieStatus(WolfieStatus.RESEARCHING));
          dispatch(
            setWolfieContextInfo({
              name: organizationName || '',
              description: organizationDescription || '',
              orgContext,
            }),
          );
          dispatch(setWolfieMinimized(false));
        }
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const deleteResult: deleteAreaRequest = await graphQlClient.request(AreasGraphQL.mutations.deleteArea, { id });
      if (deleteResult?.deleteArea?.affected > 0) {
        setAreas(areas.filter((area: Area) => area.areaId !== id));
        dispatch(appendActionMessage({ message: locAM['areaDeletedSuccessfully'], type: SnackType.SUCCESS }));
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      drawerClose();
    }
  };

  const handleDrawerSave = async (entityData: EntityData) => {
    if (!entityData.name || !entityData.description || !entityData.color) {
      dispatch(
        appendActionMessage({
          message: locAM['nameDescriptionColorRequired'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    const isEdit = drawerType === DrawerType.EDIT;

    if (isEdit && !entityData.areaId) {
      dispatch(
        appendActionMessage({
          message: localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    if (!entityData.name || !entityData.description || !entityData.color) {
      dispatch(
        appendActionMessage({
          message: locAM['fillAreaFields'],
          type: SnackType.ERROR,
        }),
      );
    }
    setSaving(true);

    try {
      const query = isEdit ? AreasGraphQL.mutations.updateArea : AreasGraphQL.mutations.createArea;
      const params = {
        area: {
          areaId: entityData.areaId,
          name: entityData.name,
          description: entityData.description,
          color: entityData.color,
        },
      };
      if (!isEdit) {
        delete params.area.areaId;
      }
      const data: createOrUpdateAreaRequest = await graphQlClient.request(query, params);
      if (data?.createArea?.areaId || data?.updateArea?.areaId) {
        if (!onboardingDetails?.areaCreated) {
          updateOrganizationAreaCreated();
        }
        if (isEdit && data?.updateArea) {
          const newArea: Area = data?.updateArea;
          setAreas(
            [...areas.filter((area: Area) => area.areaId !== entityData.areaId), newArea].sort((a, b) =>
              a.order > b.order ? 1 : -1,
            ),
          );
        } else {
          setAreas([...areas, data.createArea]);
        }
        dispatch(
          appendActionMessage({
            message: isEdit ? locAM['areaUpdatedSuccessfully'] : locAM['areaCreatedSuccessfully'] || '',
            type: SnackType.SUCCESS,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setSaving(false);
      drawerClose();
    }
  };

  const getIntegrations = async () => {
    try {
      const { calendar, storage } = checkForIntegrations(
        await graphQlClient.request(IntegrationsGraphQL.queries.getIntegrations),
      );
      setHasCalendar(calendar);
      setHasStorage(storage);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const AREA_COLORS_RANDOMIZED = AREA_COLORS.sort(() => Math.random() - 0.5);

  const handleSuggestedAreas = (newAreas: any[]) => {
    if (!newAreas || newAreas.length === 0 || !Array.isArray(newAreas)) return;
    newAreas.forEach((area, idx) => {
      area.description = area.descriptionText || '';
      area.areaId = area.id;
      area.color = AREA_COLORS_RANDOMIZED[idx];
      area.order = areas.length + 1;
    });
    setAreas((prevAreas) => [...prevAreas.filter((item) => !item.isWolfieGenerated), ...newAreas]);
  };

  const multipleElementsConfirmWrapper = async (ids: string[]) => {
    let currentId = '';
    let success = true;
    try {
      for (let id of ids) {
        currentId = id;
        success = (await handleConfirmAreaById(id, ids[0] === id, false)) || false;
        if (!success) {
          break;
        }
      }
      if (!success) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
        dispatch(setWolfieUnableToConfirmElement(true));
        dispatch(clearConfirmedWolfieElements());
      }
      setIsGenerating(false);
    } catch {}
  };

  const handleConfirmAreaById = async (id: string, showSnack: boolean = true, showErrorSnack: boolean = true) => {
    if (!id) return;
    const auxArea = areas.find((area: Area) => area.areaId === id);
    if (!auxArea) return;
    try {
      const query = AreasGraphQL.mutations.createArea;
      const params: { area: Partial<Area> } = {
        area: {
          name: auxArea.name,
          description: auxArea.description,
          color: auxArea.color,
        },
      };
      const data: createOrUpdateAreaRequest = await graphQlClient.request(query, params);
      if (data?.createArea?.areaId) {
        if (!onboardingDetails?.areaCreated) {
          updateOrganizationAreaCreated();
        }
        const position = areas.findIndex((area: Area) => area.areaId === id);
        setAreas((prevAreas) => [
          ...prevAreas.slice(0, position).filter((area) => area.areaId !== id),
          data.createArea as Area,
          ...prevAreas.slice(position).filter((area) => area.areaId !== id),
        ]);
        dispatch(removeFromWolfieData(id));
        dispatch(removeFromWolfieConfirmedElements(id));
        if (areas.filter((area: Area) => area.isWolfieGenerated).length === 1) {
          setIsGenerating(false);
        }
        if (showSnack) {
          dispatch(
            appendActionMessage({
              message: localeCommon['requestSuccess'],
              type: SnackType.SUCCESS,
            }),
          );
        }
      }
      return true;
    } catch {
      if (showErrorSnack) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
        dispatch(setWolfieUnableToConfirmElement(true));
        dispatch(removeFromWolfieConfirmedElements(id));
      }
      return false;
    }
  };

  if (wolfieFTUEStatus === WolfieFTUEStatus.MESSAGE) {
    return (
      <Box className="flex w-full">
        <Box className="w-full h-full flex items-center">
          <Box className="flex flex-wrap gap-8 align-items justify-center mx-auto" sx={{ maxWidth: '600px' }}>
            <Typography variant="h3semibold" className="text-center w-full" component="h3">
              {localeWolfie['ftueMessage1']}
            </Typography>
            <Typography variant="body16" className="text-center" component="p">
              {localeWolfie['ftueMessage2']}
            </Typography>
            <Typography variant="body16" className="text-center" component="p">
              {localeWolfie['ftueMessage3']}
            </Typography>
            <Typography variant="body16" className="text-center" component="p">
              {localeWolfie['ftueMessage4']}
            </Typography>
            <Typography variant="body16" className="text-center" component="p">
              {localeWolfie['ftueMessage5']}
            </Typography>
            <Box className="flex w-full justify-between mx-8">
              <WolfButton color="primary" variant="outlined" onClick={handleIgnoreFTUE}>
                {localeWolfie['ftueCancelButton']}
              </WolfButton>
              <WolfButton color="primary" variant="outlined" onClick={handleShowFTUE}>
                {localeWolfie['ftueStartButton']}
              </WolfButton>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {/* <Prompt when={isGenerating} message={localeCommon['changesNotSaved']} /> */}
      <Box className="flex w-full">
        <Box className="w-full">
          <Box className="sticky top-0 z-30 bg-backgroundUI">
            <GridListSubheader
              title={localeAreas['title']}
              titleLink={'/areas'}
              buttonText={role !== UserRoles.USER ? localeAreas['addNew'] : null}
              isGridView={isGridView}
              onToggleView={handleToggleView}
              onDrawerOpen={drawerNewOpen}
            />
          </Box>

          <ItemWithSummaryDrag
            isGridView={isGridView}
            isSidebarOpen={isSidebarOpen}
            elements={areas}
            elementIdName="areaId"
            showDrag={true}
            hasCalendar={hasCalendar}
            hasStorage={hasStorage}
            droppableId={`cardsArea`}
            handleDragEnd={handleDragEnd}
            drawerEditOpen={drawerEditOpen}
            handleCardClick={handleAreaClick}
          />
        </Box>
      </Box>

      <CustomDrawer
        isOpen={showDrawer}
        onClose={drawerClose}
        title={drawerType === 'edit' ? localeAreas['edit'] : localeAreas['addNew']}>
        <AddItemForm
          saving={saving}
          onClose={drawerClose}
          handleSave={handleDrawerSave}
          handleDelete={handleDelete}
          drawerType={drawerType}
          initialData={entityData}
          offerAreaColors={true}
          saveBtnText={drawerType === DrawerType.EDIT ? localeAreas['editArea'] : localeAreas['createArea']}
          existingColors={areas
            .filter((area: Area) => area.areaId !== entityData.areaId)
            .map((area: Area) => area.color)}
        />
      </CustomDrawer>
    </>
  );
};

export default AreaList;
