import { EditIcon24, TrashIcon24 } from '@components/ui/WolfIcons';
import { UserRoles } from '@models/user.model';
import { Box, Icon, styled, Typography } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import { repeatRuleToText } from '@utils/repeat-rule-to-text';
import React from 'react';

interface WolfTriggerBoxProps {
  title: string;
  subtitle?: string;
  dateFrom?: string;
  dateTo?: string;
  description?: string;
  recurrence?: string;
  slim?: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  borderRadius: '4px',
  backgroundColor: theme.palette.backgroundUI.main,
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const WolfTriggerBox: React.FC<WolfTriggerBoxProps> = ({
  title,
  subtitle,
  dateFrom,
  dateTo,
  description,
  recurrence,
  slim,
  onEdit,
  onDelete,
}) => {
  const { role } = useAppSelector((state: RootState) => state.user);
  return (
    <StyledBox>
      <Box className={'flex justify-between items-center ' + (slim ? '' : 'mb-4')}>
        <Typography variant="body16semibold">{title}</Typography>

        {!slim && role !== UserRoles.USER && (
          <IconWrapper>
            <Icon onClick={onEdit} className="p-0 cursor-pointer">
              <EditIcon24 />
            </Icon>
            <Icon onClick={onDelete} className="p-0  cursor-pointer">
              <TrashIcon24 />
            </Icon>
          </IconWrapper>
        )}
      </Box>
      <TextWrapper>
        {subtitle && (
          <Typography className="mb-4" variant="subtitle2" color="textSecondary">
            {subtitle}
          </Typography>
        )}
        {dateFrom && dateTo && (
          <Typography className="mb-4" variant="body14" color="textPrimary">
            {dateFrom} → {dateTo}
          </Typography>
        )}
        {description && (
          <Typography variant="body14" color="textSecondary">
            {description}
          </Typography>
        )}
        {recurrence && (
          <Typography variant="body14" color="textSecondary">
            {repeatRuleToText(recurrence)}
          </Typography>
        )}
      </TextWrapper>
    </StyledBox>
  );
};

export default WolfTriggerBox;
