import { graphQlClient } from '@config/graphqlClient';
import ProcessesGraphQL from '@graphql/process.queries';
import { useTranslation } from '@hooks/useTranslation';
import { getProcessesNameByIdRequest, Process } from '@models/process.model';
import { Step, StepType } from '@models/step.model';
import { UserRoles } from '@models/user.model';
import { GroupWorkOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, Fade, Icon, Paper, Typography } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import enhanceHTML from '@utils/enhanceHtml';
import { filterStepsInsideSwitchRepeat } from '@utils/filterStepsInsideSwitchRepeat';
import { getStepsInsideSwitchRepeat } from '@utils/getStepsInsideSwitchRepeat';
import sanitizeHTML from '@utils/sanitize-html';
import React, { useEffect, useState } from 'react';
import { EditIcon24 } from './ui/WolfIcons';
import WolfStepIndicator from './ui/WolfStepIndicator';

interface ProcessContentStaticProps {
  isGenerating?: boolean;
  process: Process | undefined;
  auxStep: Step | undefined;
  stepsRefs?: React.MutableRefObject<{ [key: string]: HTMLDivElement | null }>;
  handleSelectStep: (stepId: string, fromScroll: boolean) => void;
  handleEditStep: (stepId: string) => void;
}

const ProcessContentStatic: React.FC<ProcessContentStaticProps> = ({
  isGenerating,
  process,
  auxStep,
  stepsRefs,
  handleSelectStep,
  handleEditStep,
}) => {
  const localeProcesses = useTranslation('processes');
  const localeCommon = useTranslation('common');

  const { role } = useAppSelector((state: RootState) => state.user);

  const [hoveredStep, setHoveredStep] = useState<string | null>(null);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState('');
  const [processNames, setProcessNames] = useState<any>([]);

  const [enhancedHtmlMap, setEnhancedHtmlMap] = useState<{ [key: string]: { __html: string } }>({});

  const handleScroll = (event: any) => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetY = -50;
    const viewportHeight = window.innerHeight + offsetY;
    if (stepsRefs && stepsRefs?.current) {
      let matched = false;
      Object.keys(stepsRefs.current).forEach((key, index) => {
        const section = stepsRefs.current[key];
        if (section) {
          const { offsetTop, offsetHeight } = section;
          if (offsetTop < scrollTop + viewportHeight && offsetTop + offsetHeight > scrollTop && !matched) {
            handleSelectStep(key, true);
            matched = true;
          }
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getProcessNames();
    const fetchAndEnhanceHTML = async () => {
      const enhancedHtmls: { [key: string]: { __html: string } } = {};
      if (process?.steps && process?.steps.length > 0) {
        const auxSteps = [...process.steps];
        for (const step of auxSteps) {
          const sanitizedHtml = sanitizeHTML(step?.stepData?.description);
          const enhanced = await enhanceHTML(sanitizedHtml);
          enhancedHtmls[step.stepId] = enhanced;
        }
        setEnhancedHtmlMap(enhancedHtmls);
      }
    };

    if (process?.steps && process?.steps.length > 0) {
      fetchAndEnhanceHTML();
    }
  }, [process?.steps]);

  const stepDescriptionClick = (event: any) => {
    // We check that the event target is valid and that is not the placeholder image for files or broken links
    if (
      event.target.tagName === 'IMG' &&
      event.target.src &&
      event.target.alt !== 'FILE' &&
      event.target.alt !== 'BROKEN'
    ) {
      setImgSrc(event.target.src);
      setShowImageModal(true);
    }
  };

  const getProcessNames = async () => {
    // Get all referenced process ids from the process and filter out empty strings
    const processIds = process?.steps
      ?.map((step) => step?.stepData?.executeProcessId)
      .filter(Boolean)
      .join(',');

    if (!processIds) return;

    const data: getProcessesNameByIdRequest = await graphQlClient.request(
      ProcessesGraphQL.queries.getProcessesNameById,
      { id: processIds },
    );

    if (data.getProcessesNameById) {
      setProcessNames(data.getProcessesNameById);
    }
  };

  const getProcessNameById = (processId: string): string => {
    if (!processId || !processNames || processNames.length === 0) return '';
    return processNames.find((process: any) => process.processId === processId)?.name || ' ';
  };

  const closeModal = () => {
    setShowImageModal(false);
    setImgSrc('');
  };

  const stepList = filterStepsInsideSwitchRepeat(process?.steps)?.map(
    (step, index) =>
      stepsRefs && (
        <Box
          flexGrow={1}
          className="w-full my-2 p-4 px-8 pr-8 bg-white"
          key={step.stepId}
          ref={(el: HTMLDivElement) => (stepsRefs.current[step?.stepId] = el)}
          sx={{ scrollMargin: 100 }}>
          <Box
            className="flex gap-2 items-center relative"
            onMouseEnter={() => setHoveredStep(step.stepId)}
            onMouseLeave={() => setHoveredStep(null)}>
            <WolfStepIndicator text={(index + 1).toString()} type={step.stepType} isSub={false} />
            <Typography variant="h2" className="mb-6 w-fit">
              {step?.name}
            </Typography>
            {!isGenerating && role !== UserRoles.USER && hoveredStep === step.stepId && (
              <Icon onClick={() => handleEditStep(step.stepId)} className="p-0 text-sm absolute right-1">
                <EditIcon24 />
              </Icon>
            )}
          </Box>

          <Box className="flex justify-between gap-4">
            {step?.notes && (
              <Paper variant="outlined" className="p-4 !bg-utilityYellow w-1/3">
                <Typography variant="body12semibold" className="block w-full">
                  {localeProcesses['notes']}
                </Typography>
                <Typography variant="body14">{step?.notes ? step.notes : localeProcesses['noNotes']}</Typography>
              </Paper>
            )}

            {step?.stepData?.condition && (
              <Paper variant="outlined" className="p-4 border-1 border-primaryFrozen w-1/3">
                <Typography variant="body12semibold" className="block w-full">
                  {localeProcesses['condition']}
                </Typography>
                <Typography variant="body14">
                  {step?.stepData?.condition ? step.stepData.condition : localeProcesses['noCondition']}
                </Typography>
              </Paper>
            )}

            {step?.stepData?.executeProcessId && (
              <Paper
                variant="outlined"
                className="flex flex-wrap items-start justify-between p-4 border-1 border-primaryFrozen w-1/3">
                <Box className="flex items-center w-full">
                  <Typography variant="body12semibold">{localeProcesses['linkedProcess']}</Typography>
                </Box>
                <Box className="flex">
                  <GroupWorkOutlined />
                  <Typography variant="body14" className="!ml-2">
                    {getProcessNameById(step?.stepData?.executeProcessId) || localeProcesses['noLinked']}
                  </Typography>
                </Box>
              </Paper>
            )}
          </Box>
          {step?.stepData?.description && (
            <div
              onClick={stepDescriptionClick}
              className="reset-base-styles"
              dangerouslySetInnerHTML={enhancedHtmlMap[step?.stepId]}
            />
          )}
          {stepsRefs &&
            [StepType.REPEAT, StepType.SWITCH].includes(step.stepType) &&
            getStepsInsideSwitchRepeat(step?.stepData?.stepsIds, process?.steps).map((substep, idx) => (
              <Box
                className="ml-8"
                key={substep.stepId}
                ref={(el: HTMLDivElement) => (stepsRefs.current[substep?.stepId] = el)}
                sx={{ scrollMargin: 100 }}>
                <Box className="flex gap-2 items-center">
                  <WolfStepIndicator
                    text={
                      step.stepType === StepType.SWITCH
                        ? index + 1 + '.' + String.fromCharCode(idx + 65)
                        : index + 1 + '.' + (idx + 1)
                    }
                    type={step.stepType}
                    isSub={true}
                  />
                  <Typography variant="h3" className="!my-2">
                    {substep?.name}
                  </Typography>
                </Box>

                <Box className="flex justify-between gap-4">
                  {substep?.notes && (
                    <Paper variant="outlined" className="p-4 !bg-utilityYellow w-1/3">
                      <Typography variant="body12semibold" className="block w-full">
                        {localeProcesses['notes']}
                      </Typography>
                      <Typography variant="body14">
                        {substep?.notes ? substep.notes : localeProcesses['noNotes']}
                      </Typography>
                    </Paper>
                  )}

                  {substep?.stepData?.condition && (
                    <Paper variant="outlined" className="p-4 border-1 border-primaryFrozen w-1/3">
                      <Typography variant="body12semibold" className="block w-full">
                        {localeProcesses['condition']}
                      </Typography>
                      <Typography variant="body14">
                        {substep?.stepData?.condition ? substep.stepData.condition : localeProcesses['noCondition']}
                      </Typography>
                    </Paper>
                  )}

                  {substep?.stepData?.executeProcessId && (
                    <Paper
                      variant="outlined"
                      className="flex flex-wrap items-start justify-between p-4 border-1 border-primaryFrozen w-1/3">
                      <Box className="flex items-center w-full">
                        <Typography variant="body12semibold">{localeProcesses['linkedProcess']}</Typography>
                      </Box>
                      <Box className="flex">
                        <GroupWorkOutlined />
                        <Typography variant="body14" className="!ml-2">
                          {getProcessNameById(substep?.stepData?.executeProcessId) || localeProcesses['noLinked']}
                        </Typography>
                      </Box>
                    </Paper>
                  )}
                </Box>

                {substep?.stepData?.description && (
                  <div
                    onClick={stepDescriptionClick}
                    className="reset-base-styles"
                    dangerouslySetInnerHTML={enhancedHtmlMap[substep?.stepId]}
                  />
                )}
              </Box>
            ))}
        </Box>
      ),
  );

  return (
    <Box className="flex flex-wrap w-full">
      {stepList}

      {/* TODO: Separate component */}
      <Dialog
        className="flex justify-center items-center"
        open={showImageModal}
        onClose={closeModal}
        maxWidth="xl"
        closeAfterTransition>
        <Fade in={showImageModal}>
          <div className="bg-white m-10">
            <div className="flex justify-between items-center mb-5">
              <Button variant="contained" color="warning" onClick={closeModal}>
                X
              </Button>
            </div>
            <div>
              <img src={imgSrc} alt="expanded in modal" className="max-h-fit max-w-fit" />
              <div className="flex justify-between items-center">
                <Button variant="contained" color="warning" onClick={closeModal}>
                  {localeCommon['close']}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Dialog>
    </Box>
  );
};

export default ProcessContentStatic;
