import { WolfieFTUEStatus, WolfieMessage, WolfieMode, WolfieStatus, WolfieSuggestions } from '@models/wolfie.model';
import { ContextInfo } from '@utils/ai/generateContextMessage';
import { PersistPartial } from 'redux-persist/es/persistReducer';

export const WolfieTypes = {
  SET_WOLFIE_CONTEXT_INFO: 'SET_WOLFIE_CONTEXT_INFO',
  SET_WOLFIE_ENABLED: 'SET_WOLFIE_ENABLED',
  SET_WOLFIE_MINIMIZED: 'SET_WOLFIE_MINIMIZED',
  SET_WOLFIE_SHOW_ATTACH: 'SET_WOLFIE_SHOW_ATTACH',
  SET_WOLFIE_SHOW_SUGGESTIONS: 'SET_WOLFIE_SHOW_SUGGESTIONS',
  SET_WOLFIE_DATA: 'SET_WOLFIE_DATA',
  REMOVE_FROM_WOLFIE_DATA: 'REMOVE_FROM_WOLFIE_DATA',
  SET_WOLFIE_STATUS: 'SET_WOLFIE_STATUS',
  SET_WOLFIE_MESSAGES: 'SET_WOLFIE_MESSAGES',
  SET_WOLFIE_LOADING: 'SET_WOLFIE_LOADING',
  SET_WOLFIE_SUGGESTIONS: 'SET_WOLFIE_SUGGESTIONS',
  SET_WOLFIE_FTUE_STATUS: 'SET_WOLFIE_FTUE_STATUS',
  DISABLE_WOLFIE: 'DISABLE_WOLFIE',
  PERMANENTLTY_DISABLE_WOLFIE: 'PERMANENTLTY_DISABLE_WOLFIE',
  PERMANENTLTY_REENABLE_WOLFIE: 'PERMANENTLTY_REENABLE_WOLFIE',
  CLEAR_CONFIRMED_WOLFIE_ELEMENTS: 'CLEAR_CONFIRMED_WOLFIE_ELEMENTS',
  CONFIRM_ALL_WOLFIE_ELEMENTS: 'CONFIRM_ALL_WOLFIE_ELEMENTS',
  CONFIRM_WOLFIE_ELEMENT: 'CONFIRM_WOLFIE_ELEMENT',
  REMOVE_FROM_WOLFIE_CONFIRMED_ELEMENTS: 'REMOVE_FROM_WOLFIE_CONFIRMED_ELEMENTS',
  SET_WOLFIE_UNABLE_TO_CONFIRM_ELEMENT: 'SET_WOLFIE_UNABLE_TO_CONFIRM_ELEMENT',
  SET_WOLFIE_CURRENTLY_EDITING: 'SET_WOLFIE_CURRENTLY_EDITING',
  CLEAR_WOLFIE_CURRENTLY_EDITING: 'CLEAR_WOLFIE_CURRENTLY_EDITING',
  SET_WOLFIE_VIDEO_MODE: 'SET_WOLFIE_VIDEO_MODE',
  SET_WOLFIE_SPEAKING: 'SET_WOLFIE_SPEAKING',
  SET_WOLFIE_SHOULD_REFRESH_DATA: 'SET_WOLFIE_SHOULD_REFRESH_DATA',
};

export const setWolfieContextInfo = (context: ContextInfo) => ({
  type: WolfieTypes.SET_WOLFIE_CONTEXT_INFO,
  data: context,
});

export const setWolfieEnabled = (enabled: boolean) => ({
  type: WolfieTypes.SET_WOLFIE_ENABLED,
  data: enabled,
});

export const setWolfieMinimized = (minimized: boolean) => ({
  type: WolfieTypes.SET_WOLFIE_MINIMIZED,
  data: minimized,
});

export const setWolfieShowAttach = (showAttach: boolean) => ({
  type: WolfieTypes.SET_WOLFIE_SHOW_ATTACH,
  data: showAttach,
});

export const setWolfieShowSuggestions = (showSuggestions: boolean) => ({
  type: WolfieTypes.SET_WOLFIE_SHOW_SUGGESTIONS,
  data: showSuggestions,
});

export const setWolfieData = (data: any) => ({
  type: WolfieTypes.SET_WOLFIE_DATA,
  data,
});

export const removeFromWolfieData = (id: string) => ({
  type: WolfieTypes.REMOVE_FROM_WOLFIE_DATA,
  data: id,
});

export const setWolfieStatus = (status: WolfieStatus) => ({
  type: WolfieTypes.SET_WOLFIE_STATUS,
  data: status,
});

export const confirmWolfieElement = (id: string) => ({
  type: WolfieTypes.CONFIRM_WOLFIE_ELEMENT,
  data: id,
});

export const clearConfirmedWolfieElements = () => ({
  type: WolfieTypes.CLEAR_CONFIRMED_WOLFIE_ELEMENTS,
  data: {},
});

export const confirmAllWolfieElements = () => ({
  type: WolfieTypes.CONFIRM_ALL_WOLFIE_ELEMENTS,
  data: {},
});

export const removeFromWolfieConfirmedElements = (id: string) => ({
  type: WolfieTypes.REMOVE_FROM_WOLFIE_CONFIRMED_ELEMENTS,
  data: id,
});

export const setWolfieUnableToConfirmElement = (unableToConfirmElement: boolean) => ({
  type: WolfieTypes.SET_WOLFIE_UNABLE_TO_CONFIRM_ELEMENT,
  data: unableToConfirmElement,
});

export const setWolfieMessages = (messages: any[]) => ({
  type: WolfieTypes.SET_WOLFIE_MESSAGES,
  data: messages,
});

export const setWolfieSuggestions = (suggestions: WolfieSuggestions[]) => ({
  type: WolfieTypes.SET_WOLFIE_SUGGESTIONS,
  data: suggestions,
});

export const setWolfieLoading = (loading: boolean) => ({
  type: WolfieTypes.SET_WOLFIE_LOADING,
  data: loading,
});

export const setWolfieFTUEStatus = (statusFTUE: WolfieFTUEStatus | null) => ({
  type: WolfieTypes.SET_WOLFIE_FTUE_STATUS,
  data: statusFTUE,
});

export const disableWolfie = () => ({
  type: WolfieTypes.DISABLE_WOLFIE,
  data: {},
});

export const permanentlyDisableWolfie = () => ({
  type: WolfieTypes.PERMANENTLTY_DISABLE_WOLFIE,
  data: {},
});

export const permanentlyReenableWolfie = () => ({
  type: WolfieTypes.PERMANENTLTY_REENABLE_WOLFIE,
  data: {},
});

export const setWolfieCurrentlyEditing = (currentlyEditing: string | null) => ({
  type: WolfieTypes.SET_WOLFIE_CURRENTLY_EDITING,
  data: currentlyEditing,
});

export const clearWolfieCurrentlyEditing = () => ({
  type: WolfieTypes.CLEAR_WOLFIE_CURRENTLY_EDITING,
  data: null,
});

export const setWolfieVideoMode = (videoMode: boolean) => ({
  type: WolfieTypes.SET_WOLFIE_VIDEO_MODE,
  data: videoMode,
});

export const setWolfieSpeaking = (speaking: boolean) => ({
  type: WolfieTypes.SET_WOLFIE_SPEAKING,
  data: speaking,
});

export const setWolfieShouldRefreshData = (shouldRefreshData: boolean) => ({
  type: WolfieTypes.SET_WOLFIE_SHOULD_REFRESH_DATA,
  data: shouldRefreshData,
});

export type WolfieState = {
  context: any;
  confirmedElements: string[];
  messages: WolfieMessage[];
  loading: false;
  mode: WolfieMode | null;
  status: 'researching' | 'done' | 'declined';
  statusFTUE: WolfieFTUEStatus | null;
  enabled: boolean;
  permanentlyDisabled: boolean;
  minimized: boolean;
  showAttach: boolean;
  showSuggestions: boolean;
  suggestions: WolfieSuggestions[];
  data: any;
  currentlyEditing: string | null;
  unableToConfirmElement: boolean;
  videoMode: boolean;
  speaking: boolean;
  shouldRefreshData: boolean;
} & PersistPartial;

// INITIAL STATE
const wolfieInitialState: WolfieState & PersistPartial = {
  context: {},
  confirmedElements: [],
  messages: [],
  loading: false,
  mode: WolfieMode.V2_INTERACTION,
  status: 'researching',
  statusFTUE: null,
  enabled: true,
  permanentlyDisabled: false,
  minimized: true,
  showAttach: false,
  showSuggestions: false,
  suggestions: [],
  data: null,
  currentlyEditing: null,
  unableToConfirmElement: false,
  videoMode: false,
  speaking: false,
  shouldRefreshData: false,
  _persist: {
    version: 0,
    rehydrated: false,
  },
};

export type WolfieAction = {
  type: string;
  data: any;
};

const wolfieReducer = (state = wolfieInitialState, action: WolfieAction) => {
  switch (action.type) {
    case WolfieTypes.SET_WOLFIE_CONTEXT_INFO:
      return {
        ...state,
        context: action.data,
      };

    case WolfieTypes.SET_WOLFIE_ENABLED:
      return {
        ...state,
        enabled: !state.permanentlyDisabled && action.data,
        mode: WolfieMode.V2_INTERACTION,
      };

    case WolfieTypes.SET_WOLFIE_MINIMIZED:
      return {
        ...state,
        minimized: action.data,
      };

    case WolfieTypes.SET_WOLFIE_SHOW_ATTACH:
      return {
        ...state,
        showAttach: action.data,
      };

    case WolfieTypes.SET_WOLFIE_SHOW_SUGGESTIONS:
      return {
        ...state,
        showSuggestions: action.data,
      };

    case WolfieTypes.SET_WOLFIE_DATA:
      return {
        ...state,
        data: action.data,
      };

    case WolfieTypes.REMOVE_FROM_WOLFIE_DATA:
      return {
        ...state,
        data: state.data.filter((element: any) => element.id !== action.data),
      };

    case WolfieTypes.SET_WOLFIE_STATUS:
      return {
        ...state,
        status: action.data,
      };

    case WolfieTypes.CONFIRM_WOLFIE_ELEMENT:
      return {
        ...state,
        confirmedElements: [...state.confirmedElements, action.data],
      };

    case WolfieTypes.CLEAR_CONFIRMED_WOLFIE_ELEMENTS:
      return {
        ...state,
        confirmedElements: [],
      };

    case WolfieTypes.CONFIRM_ALL_WOLFIE_ELEMENTS:
      return {
        ...state,
        confirmedElements: state.data.map((element: any) => element.id),
      };

    case WolfieTypes.REMOVE_FROM_WOLFIE_CONFIRMED_ELEMENTS:
      return {
        ...state,
        confirmedElements: state.confirmedElements.filter((id: string) => id !== action.data),
      };

    case WolfieTypes.SET_WOLFIE_UNABLE_TO_CONFIRM_ELEMENT:
      return {
        ...state,
        unableToConfirmElement: action.data,
      };

    case WolfieTypes.SET_WOLFIE_MESSAGES:
      return {
        ...state,
        // TODO: This is a temporary fix to avoid the messages array from growing too much, we may want to implement a more nuanced approach
        messages: action.data.slice(-100),
      };

    case WolfieTypes.SET_WOLFIE_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.data,
      };

    case WolfieTypes.SET_WOLFIE_LOADING:
      return {
        ...state,
        loading: action.data,
      };

    case WolfieTypes.SET_WOLFIE_FTUE_STATUS:
      return {
        ...state,
        statusFTUE: action.data,
      };

    case WolfieTypes.DISABLE_WOLFIE:
      return {
        ...state,
        enabled: false,
        confirmedElements: [],
        data: null,
        messages: [],
        mode: null,
        loading: false,
      };
    case WolfieTypes.PERMANENTLTY_DISABLE_WOLFIE:
      return {
        ...state,
        permanentlyDisabled: true,
        enabled: false,
        data: null,
        messages: [],
        mode: null,
        loading: false,
      };

    case WolfieTypes.PERMANENTLTY_REENABLE_WOLFIE:
      return {
        ...state,
        permanentlyDisabled: false,
      };

    case WolfieTypes.SET_WOLFIE_CURRENTLY_EDITING:
      return {
        ...state,
        currentlyEditing: action.data,
      };

    case WolfieTypes.CLEAR_WOLFIE_CURRENTLY_EDITING:
      return {
        ...state,
        currentlyEditing: null,
      };

    case WolfieTypes.SET_WOLFIE_VIDEO_MODE:
      return {
        ...state,
        videoMode: action.data,
      };

    case WolfieTypes.SET_WOLFIE_SPEAKING:
      return {
        ...state,
        speaking: action.data,
      };

    case WolfieTypes.SET_WOLFIE_SHOULD_REFRESH_DATA:
      return {
        ...state,
        shouldRefreshData: action.data,
      };

    default:
      return state;
  }
};

export default wolfieReducer;
