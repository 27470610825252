// TODO: Add translations to this component
export const repeatRuleToText = (rrule: string): string | null => {
  if (!rrule) return '';

  console.log();

  const values = rrule.replace('RRULE:', '').split(';');

  let repeatType = '';
  let repeatEvery = 1;
  let selectedDays: string[] = [];
  let selectedDay = '';
  let selectedMonth = 1;
  let positions: string[] = [];
  const hasPosition = rrule.includes('BYSETPOS');
  let humanReadableString = '';

  values.forEach((value) => {
    const [key, val] = value.split('=');
    switch (key) {
      case 'FREQ':
        repeatType = val;
        break;
      case 'INTERVAL':
        repeatEvery = parseInt(val, 10);
        break;
      case 'BYDAY':
        selectedDays = val.split(',');
        break;
      case 'BYMONTHDAY':
        selectedDay = val;
        break;
      case 'BYMONTH':
        selectedMonth = parseInt(val, 10);
        break;
      case 'BYSETPOS':
        positions = val.split(',');
        break;
    }
  });

  switch (repeatType) {
    case 'DAILY':
      humanReadableString = `Every ${repeatEvery || ''} day${repeatEvery > 1 ? 's' : ''}`;
      break;
    case 'WEEKLY':
      const days = selectedDays.map((day) => dayMap[day]).join(', ');
      humanReadableString = `Every ${repeatEvery || ''} week${repeatEvery > 1 ? 's' : ''} on ${days}`;
      break;
    case 'MONTHLY':
      if (hasPosition) {
        if (positions.length > 1) {
          // Handle multiple positions (e.g., "Second and last Thursday")
          const positionStrings = positions.map((pos) => positionMap[pos.trim()]);
          const lastPosition = positionStrings.pop();
          const positionsText =
            positionStrings.length > 0 ? positionStrings.join(', ') + ' and ' + lastPosition : lastPosition;
          humanReadableString = `Every ${repeatEvery || ''} month${repeatEvery > 1 ? 's' : ''} on the ${positionsText} ${dayMap[selectedDays[0]] || ''}`;
        } else {
          humanReadableString = `Every ${repeatEvery || ''} month${repeatEvery > 1 ? 's' : ''} on the ${positionMap[positions[0]] || ''} ${dayMap[selectedDays[0]] || ''}`;
        }
      } else {
        humanReadableString = `Every ${repeatEvery || ''} month${repeatEvery > 1 ? 's' : ''} on day ${selectedDay || ''}`;
      }
      break;
    case 'YEARLY':
      if (hasPosition) {
        humanReadableString = `Every year on the ${positionMap[positions[0]] || ''} ${dayMap[selectedDays[0]] || ''} of ${monthMap[selectedMonth] || ''}`;
      } else {
        humanReadableString = `Every year on ${monthMap[selectedMonth] || ''} ${selectedDay || ''}`;
      }
      break;
    default:
      humanReadableString = 'No repetition';
      break;
  }

  return humanReadableString;
};

const dayMap: { [key: string]: string } = {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
};

const monthMap: { [key: number]: string } = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const positionMap: { [key: string]: string } = {
  '1': 'First',
  '2': 'Second',
  '3': 'Third',
  '4': 'Fourth',
  '-1': 'Last',
};
