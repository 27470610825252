import fuzzyBg from '@assets/fuzzy-bg.png';
import wolfieLogo from '@assets/logos/mrwolf-transparent-logo.png';
import WolfAvatar from '@components/ui/WolfAvatar';
import { getTokenLocalStorage, getTokenSessionStorage } from '@config/storage';
import { ChevronRight, Close, KeyboardAltOutlined, Mic, MicOff } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { setWolfieLoading, setWolfieMinimized, setWolfieSpeaking } from '@redux/reducers/wolfie.reducer';
import { RootState } from '@redux/store';
import { blobToBase64 } from '@utils/blobToBase64';
import { useRef, useState } from 'react';

const WolfieHub = ({
  isTalking,
  onSubmit,
  //   onRecording,
}: {
  isTalking: boolean;
  onSubmit: (text: string) => void;
  //   onRecording: (recording: boolean) => void;
}) => {
  const { avatar, firstName, rememberMe } = useAppSelector((state: RootState) => state.user);
  const {
    minimized: wolfieMinimized,
    speaking: wolfieSpeaking,
    loading,
  } = useAppSelector((state: RootState) => state.wolfie);

  const [recording, setRecording] = useState(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);

  const [isMicOn, setIsMicOn] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(wolfieMinimized);
  const [talkEnabled, setTalkEnabled] = useState(wolfieSpeaking);

  const dispatch = useAppDispatch();

  const toggleMic = () => {
    setIsMicOn((prev) => !prev);
    if (isMicOn) {
      stopRecording();
      //   onRecording(false);
    } else {
      startRecording();
      //   onRecording(true);
    }
  };

  const toggleChat = () => {
    console.log('toggleChat', wolfieMinimized);
    dispatch(setWolfieMinimized(!wolfieMinimized));
    setIsChatOpen((prev) => !prev);
  };

  const toggleTalk = () => {
    if (wolfieSpeaking) {
      speechSynthesis.cancel();
    }
    setTalkEnabled((prev) => !prev);
    dispatch(setWolfieSpeaking(!wolfieSpeaking));
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };
      mediaRecorder.current.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
        audioChunks.current = [];
        transcribeAudio(audioBlob);
        // Stop all tracks to free up the mic
        stream.getTracks().forEach((track) => track.stop());
      };
      mediaRecorder.current.start();
      setRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setRecording(false);
    }
  };

  async function transcribeAudio(audioBlob: Blob) {
    try {
      dispatch(setWolfieLoading(true));
      const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();
      const base64Audio = await blobToBase64(audioBlob);
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/transcribeAudio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          audioBase64: base64Audio,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        const transcription = result.transcription;
        onSubmit(transcription);
      } else {
        console.error('Backend error:', result.message);
      }
    } catch (error) {
      console.error('Error transcribing audio:', error);
    }
  }

  return (
    <Box className="flex items-center justify-between gap-5 bg-frozenGrayShades9 px-4 py-2 rounded-full shadow-shadow3modals w-72 h-18">
      <Box className="flex items-center gap-2">
        {/* Mr. Wolf Logo */}
        <Box
          className="relative flex items-center justify-center w-10 h-10 rounded-full p-4 overflow-hidden"
          sx={{
            backgroundImage: `url(${fuzzyBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
          <img src={wolfieLogo} alt="Wolfie Logo" className="w-6 h-6 relative z-10" />
        </Box>

        {/* Animated Audio Waves */}
        {loading ? (
          <Box className="relative w-10 h-10 flex items-center justify-center rounded-full overflow-hidden bg-primaryIcy">
            <CircularProgress size={16} color="primary" />
          </Box>
        ) : (
          <Box
            className="relative w-10 h-6 flex items-center justify-center cursor-pointer border border-frozenGrayShades8 border-solid rounded-full overflow-hidden"
            onClick={toggleTalk}>
            {!talkEnabled && (
              <Close className="absolute z-10 opacity-50 text-utilityWarning" sx={{ fontSize: '1rem' }} />
            )}
            <Box className="flex items-end gap-1 h-6 w-6" sx={{ opacity: talkEnabled ? 1 : 0.3 }}>
              <Box
                className={`h-2 w-1 bg-white ${wolfieSpeaking && isTalking ? 'animate-wave1' : ''}`}
                sx={{ height: talkEnabled ? undefined : '8px' }}
              />
              <Box
                className={`h-4 w-1 bg-white ${wolfieSpeaking && isTalking ? 'animate-wave2' : ''}`}
                sx={{ height: talkEnabled ? undefined : '8px' }}
              />
              <Box
                className={`h-3 w-1 bg-white ${wolfieSpeaking && isTalking ? 'animate-wave3' : ''}`}
                sx={{ height: talkEnabled ? undefined : '8px' }}
              />
              <Box
                className={`h-5 w-1 bg-white ${wolfieSpeaking && isTalking ? 'animate-wave2' : ''}`}
                sx={{ height: talkEnabled ? undefined : '8px' }}
              />
              <Box
                className={`h-2 w-1 bg-white ${wolfieSpeaking && isTalking ? 'animate-wave1' : ''}`}
                sx={{ height: talkEnabled ? undefined : '8px' }}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box className="flex items-center gap-2 bg-primaryDark rounded-full p-2">
        {/* User Avatar */}
        <Box className="w-10 h-10 rounded-full overflow-auto">
          <WolfAvatar src={avatar || ''} alt={firstName || ''} size="40px" />
        </Box>

        {/* Microphone Toggle */}
        <IconButton
          onClick={toggleMic}
          className={
            'bg-gray-800 hover:bg-gray-700 rounded-full p-2 !text-white cursor-pointer ' +
            (isMicOn ? '!bg-utilityWarning' : '!bg-frozenGrayShades8')
          }>
          {isMicOn ? <Mic /> : <MicOff />}
        </IconButton>

        {/* Chat Toggle */}
        <IconButton
          onClick={toggleChat}
          className="bg-frozenGrayShades7 hover:bg-frozenGrayShades5 rounded-full p-2 !text-white cursor-pointer">
          {isChatOpen ? <ChevronRight className="rotate-90" /> : <KeyboardAltOutlined />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default WolfieHub;
