import {
  considerThisFilePrompt,
  emptyAreaInitialMessage,
  emptyProcessInitialMessage,
  emptyServiceInitialMessage,
  expandAreaInitialMessage,
  firstTimeUserInitialMessage,
  generalExplanation,
  noAreasInitialMessage,
  resetFTUEInitialMessage,
} from '@assets/ai/prompts';
import WolfInput from '@components/ui/WolfInput';
import WolfTooltip from '@components/ui/WolfTooltip';
import { graphQlClient } from '@config/graphqlClient';
import { getTokenLocalStorage, getTokenSessionStorage } from '@config/storage';
import UserGraphQL from '@graphql/user.queries';
import { useTranslation } from '@hooks/useTranslation';
import { getLastMessagesRequest } from '@models/user.model';
import { PreProcessActionType, WolfieMode, WolfieStatus, WolfieSuggestions } from '@models/wolfie.model';
import { Minimize, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { FormControl, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector } from '@redux/hooks';
import {
  confirmAllWolfieElements,
  disableWolfie,
  setWolfieData,
  setWolfieEnabled,
  setWolfieLoading,
  setWolfieMessages,
  setWolfieMinimized,
  setWolfieShouldRefreshData,
  setWolfieStatus,
  setWolfieSuggestions,
} from '@redux/reducers/wolfie.reducer';
import { RootState } from '@redux/store';
import {
  ContextInfo,
  generateBlankAreaContextMessage,
  generateBlankProcessContextMessage,
  generateBlankServiceContextMessage,
  generateExpandAreaContextMessage,
  generateFirstTimeContextMessage,
  generateNoAreasContextMessage,
  generateResetFtueContextMessage,
} from '@utils/ai/generateContextMessage';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import WolfieHub from './WolfieHub';

export interface WolfieProps {}

const Wolfie: React.FC<WolfieProps> = ({}) => {
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const localeCommon = useTranslation('common');
  const localeWolfie = useTranslation('wolfie');

  const dispatch = useDispatch();
  const history = useHistory();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const ALLOWED_WOLFIE_REDIRECTS = ['processes', 'areas', 'services'];
  const enabledWolfieVoice = process.env.REACT_APP_ENABLE_VOICE_WOLFIE === 'true';
  const enabledWolfieVideo = process.env.REACT_APP_ENABLE_VIDEO_WOLFIE === 'true';
  const [input, setInput] = useState('');
  const [fileError, setFileError] = useState<string | null>(null);
  const [wolfieTalking, setWolfieTalking] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const {
    name: organizationName,
    description: organizationDescription,
    context: organizationContext,
  } = useAppSelector((state: RootState) => state.organization);
  const { avatar, rememberMe, role: userRole } = useAppSelector((state: RootState) => state.user);
  const {
    context: contextInfo,
    enabled: wolfieEnabled,
    mode,
    showAttach,
    showSuggestions,
    minimized,
    confirmedElements,
    messages: wolfieMessages,
    loading: wolfieLoading,
    status: currentStatus,
    data: wolfieData,
    suggestions: wolfieSuggestions,
    currentlyEditing: currentlyEditingWolfie,
    videoMode: wolfieVideoMode,
    unableToConfirmElement,
    speaking: wolfieSpeaking,
  } = useAppSelector((state: RootState) => state.wolfie);

  // So that we scroll when new messages are added
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [wolfieMessages, minimized]);

  useEffect(() => {
    if (wolfieSpeaking) {
      setWolfieTalking(false);
    } else {
      setWolfieTalking(false);
      speechSynthesis.cancel();
    }
  }, [wolfieSpeaking]);

  useEffect(() => {
    dispatch(setWolfieEnabled(true));
    getLastMessages();
  }, []);

  const onClose = () => {
    dispatch(setWolfieMinimized(true));
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const open = () => {
    dispatch(setWolfieMinimized(false));
  };

  const getLastMessages = async () => {
    try {
      const lastMessages: getLastMessagesRequest = await graphQlClient.request(UserGraphQL.queries.getLastMessages);

      const lastMessage = lastMessages.getLastMessages[lastMessages.getLastMessages.length - 1].content
        .split('---')
        .reverse()[0];
      const wolfieLastMessage = wolfieMessages[wolfieMessages.length - 1]?.content || '';

      console.log('lastMessage', lastMessage);
      console.log('wolfieLastMessage', wolfieLastMessage);
      console.log('lastMessage && lastMessage === wolfieLastMessage', lastMessage && lastMessage === wolfieLastMessage);

      if (lastMessage && lastMessage === wolfieLastMessage) {
        return;
      } else {
        const transformedMessages = lastMessages.getLastMessages.flatMap((message) => {
          const contentParts = message.content.split('---');
          return contentParts.map((contentPart: any) => ({
            messageId: uuidv4(),
            role: message.role,
            content: contentPart.trim(),
            createdAt: message.createdAt,
          }));
        });
        dispatch(setWolfieMessages(transformedMessages));
      }
    } catch (error) {
      console.error('Error getting last messages:', error);
    }
  };

  const handleSuggestionSelection = async (suggestion: WolfieSuggestions) => {
    // We treat the suggestion as a user message and add it to the conversation
    const newMessage = {
      role: 'user',
      content: suggestion,
      id: Math.random().toString(36).slice(2, 20),
    };
    const updatedMessages = [...wolfieMessages, newMessage];
    dispatch(setWolfieMessages(updatedMessages));
    sendMessage(updatedMessages);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!input.trim()) return;
    dispatch(setWolfieLoading(true));

    // Add the user's message to the conversation history
    const newMessage = {
      role: 'user',
      content: input,
      id: Math.random().toString(36).slice(2, 20),
    };
    const updatedMessages = [...wolfieMessages, newMessage];
    dispatch(setWolfieMessages(updatedMessages));
    setInput('');
    dispatch(setWolfieLoading(false));
    if (mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE, WolfieMode.V2_INTERACTION].includes(mode)) {
      dispatch(setWolfieData([]));
      sendMessage(updatedMessages);
      return;
    }
    // const preProcessedMessage = await preProcessMessage(input);
    // if (!preProcessedMessage) {
    //   dispatch(setWolfieLoading(false));
    //   return;
    // } else if (preProcessedMessage === 'Clarify') {
    //   const updatedMessagesClarify = [
    //     ...updatedMessages,
    //     {
    //       role: 'assistant',
    //       content: 'I am sorry, I did not understand your request. Could you please clarify?',
    //       id: Math.random().toString(36).slice(2, 20),
    //     },
    //   ];
    //   dispatch(setWolfieMessages(updatedMessagesClarify));
    //   dispatch(setWolfieLoading(false));
    //   return;
    // } else if (preProcessedMessage === 'Multiple') {
    //   const updatedMessagesClarify = [
    //     ...updatedMessages,
    //     {
    //       role: 'assistant',
    //       content:
    //         'I am sorry, I did not understand your request. There seems to be more than one instruction, could we go over them one by one?',
    //       id: Math.random().toString(36).slice(2, 20),
    //     },
    //   ];
    //   dispatch(setWolfieMessages(updatedMessagesClarify));
    //   dispatch(setWolfieLoading(false));
    //   return;
    // }

    // const auxNewMessage = {
    //   role: 'user',
    //   content: preProcessedMessage,
    //   id: Math.random().toString(36).slice(2, 20),
    // };
    // const auxUpdatedMessages = [...wolfieMessages, auxNewMessage];
    // sendMessage(auxUpdatedMessages, updatedMessages);
  };

  // TODO: Check message workflow
  // TODO: Should also get preprocessed?
  const handleSubmitAudio = (transcription: string) => {
    const newMessage = {
      role: 'user',
      content: transcription,
      id: Math.random().toString(36).slice(2, 20),
    };
    const updatedMessages = [...wolfieMessages, newMessage];
    dispatch(setWolfieMessages(updatedMessages));
    sendMessage(updatedMessages);
  };

  const preProcessMessage = async (message: string) => {
    try {
      const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();

      const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/preProcessMessage', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch message response');
      }

      const result = await response.json();

      const action = result.action as PreProcessActionType;

      if (!action || action === 'Clarify') {
        return 'Clarify';
      }
      // TODO: Here we should upgrade the message to a more detailed one according to the action
      switch (action) {
        case 'Conversational':
          return 'Conversational';
        case 'Multiple':
          return 'Multiple';
        case 'Restart':
          return `The user has requested to restart the process. Discard the data array and start over.`;
        case 'Edit':
          return `The user has requested the following edit:${message}. 
          Return a new array of data considering that edit. 
          Make as little changes as possible to the previous data array
          Here is the previous Data array: ${JSON.stringify(wolfieData)}`;
        // TODO: Check if sending the data array is always needed
        case 'Add':
          return `The user has requested the following addition:${message}. 
          Return a new array of data considering that addition. 
          Make as little changes as possible to the previous data array
          Here is the previous Data array: ${JSON.stringify(wolfieData)}`;
        case 'Delete':
          return `The user has requested the following removal:${message}. 
          Return a new array of data considering that removal. 
          Make as little changes as possible to the previous data array
          Here is the previous Data array: ${JSON.stringify(wolfieData)}`;
        case 'Conversational':
          return message;
        case 'Done':
          return 'THe user has requested the following, seems to be done, if so, change the status to Done' + message;
        case 'Declined':
          return (
            'The user has requested the following, seems to be declined, if so, change the status to Declined' + message
          );

        case 'Navigate':
          const destination = result?.destination;
          // If we need to navigate away, we clear the status and context, and move to the new page if it is on an expected direction
          if (ALLOWED_WOLFIE_REDIRECTS.includes(destination)) {
            const newMessage = {
              role: 'assisstant',
              // TODO: We should translate this
              content: 'Navigating to ' + destination,
              id: Math.random().toString(36).slice(2, 20),
            };
            const updatedMessages = [...wolfieMessages, newMessage];
            dispatch(setWolfieMessages(updatedMessages));
            dispatch(disableWolfie());
            history.push('/' + destination);
          } else {
            return 'Clarify';
          }

          return null;
      }
    } catch (error) {
      return {
        action: null,
        success: false,
        response: 'An error occurred while processing your request.',
        error: error instanceof Error ? error.message : String(error),
      };
    }
  };

  const sendMessage = async (updatedMessages: any, updatedMessagesForState?: any) => {
    dispatch(setWolfieLoading(true));
    const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();
    let response;
    try {
      let data;
      if (mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode)) {
        response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/sendMessageFTUE', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ messages: updatedMessages }),
        });
      } else if (mode === WolfieMode.V2_INTERACTION) {
        try {
          response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/v2/interaction', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              message: updatedMessages[updatedMessages.length - 1].content.trim(),
            }),
          });
          data = await response.json();
        } catch (error) {
          console.error('Error sending message:', error);
        }

        console.log('data', data);
        let newMessages: any[] = [];
        try {
          if (!data?.message?.content) {
            newMessages = [
              {
                role: 'assistant',
                content: 'I am sorry, I was unable to respond to your message. Could you please try again?',
                id: Math.random().toString(36).slice(2, 20),
              },
            ];
            dispatch(setWolfieLoading(false));
          } else {
            newMessages = data?.message?.content?.split('---').map((message: any) => ({
              role: 'assistant',
              content: message,
              id: Math.random().toString(36).slice(2, 20),
            }));
            console.log('data.metadata', data.metadata);

            if (data?.metadata?.suggestions) {
              dispatch(setWolfieSuggestions(data.metadata.suggestions));
            } else {
              dispatch(setWolfieSuggestions([]));
            }

            if (data?.metadata?.navigateTo) {
              dispatch(setWolfieLoading(false));
              history.push('/' + data.metadata.navigateTo);
            }

            if (data?.metadata?.shouldRefreshData) {
              dispatch(setWolfieShouldRefreshData(true));
            }

            dispatch(setWolfieLoading(false));
          }
        } catch (error) {
          console.error('Error parsing response:', error);
          dispatch(setWolfieLoading(false));
          newMessages = [
            {
              role: 'assistant',
              content: 'I am sorry, I was unable to process that. Please try again.',
              id: Math.random().toString(36).slice(2, 20),
            },
          ];
        }

        dispatch(setWolfieMessages([...updatedMessages, ...newMessages]));
        // TODO: Remove??
        if (data?.result?.data?.values) {
          dispatch(setWolfieData(data.result.data.values.split(',')));
          console.log('data.result.data.values.split(', ')', data.result.data.values.split(','));
        }
      } else {
        response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/sendMessageLG', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify({ messages: updatedMessages }),
          body: JSON.stringify({ message: updatedMessages[updatedMessages.length - 1].content }),
        });
      }
      if (!response || !wolfieEnabled) {
        return;
      }

      console.log('response', response);

      let aiResponse;
      if (response && WolfieMode.V2_INTERACTION !== mode) {
        try {
          aiResponse = await response.json();
        } catch (error) {
          console.error('Error parsing AI response:', error);
          dispatch(setWolfieLoading(false));
          return;
        }
      }

      setWolfieTalking(false);
      if (wolfieSpeaking) {
        setWolfieTalking(true);
        data?.message?.content.split('---').forEach((message: any, index: number, array: any[]) => {
          const speech = new SpeechSynthesisUtterance(message);
          speech.lang = 'en-US';

          // Only trigger when the last message finishes
          if (index === array.length - 1) {
            speech.onend = () => {
              setWolfieTalking(false);
            };
          }
          speechSynthesis.speak(speech);
        });
      }

      const messageContent =
        aiResponse?.choices &&
        aiResponse?.choices.length > 0 &&
        aiResponse.choices[0].message?.tool_calls[0].function.arguments;

      console.log('messageContent', messageContent);
      if (!messageContent) {
        dispatch(setWolfieLoading(false));

        return;
      }

      const functionCall: any =
        aiResponse.choices[0].message?.tool_calls && aiResponse.choices[0].message?.tool_calls[0];
      let parsedContent;
      if (functionCall.function.name === 'generate_response') {
        parsedContent = JSON.parse(functionCall.function.arguments);
        let newMessages = [];
        // TODO: CHECK IF IS AND ARRAY
        if (parsedContent.shortMessages) {
          newMessages = parsedContent.shortMessages.map((message: any) => ({
            role: 'assistant',
            content: message,
            id: Math.random().toString(36).slice(2, 20),
          }));
        }
        if (updatedMessagesForState) {
          dispatch(
            setWolfieMessages([
              ...updatedMessagesForState,
              ...newMessages,
              // { content: parsedContent.verbalResponse, role: 'assistant', id: Math.random().toString(36).slice(2, 20) },
            ]),
          );
        } else {
          dispatch(
            setWolfieMessages([
              ...updatedMessages,
              ...newMessages,
              // { content: parsedContent.verbalResponse, role: 'assistant', id: Math.random().toString(36).slice(2, 20) },
            ]),
          );
        }

        console.log('will try to speak!', wolfieSpeaking);

        // Speak the response if enabled. Using basic browser speech synthesis

        // Add isWolfieGenerated flag to each element of the data array
        if (
          parsedContent?.data &&
          parsedContent.data.length > 0 &&
          !(mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode))
        ) {
          parsedContent.data.forEach((element: any) => {
            element.isWolfieGenerated = true;
          });
        }

        if (
          parsedContent?.data &&
          parsedContent.data.length > 0 &&
          !(mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode))
        ) {
          dispatch(setWolfieData(parsedContent.data));
          // onDataGenerated(parsedContent.data || []);
        }

        if (parsedContent?.data && mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode)) {
          dispatch(setWolfieData(parsedContent.data));
        }

        if (
          parsedContent?.status.toLowerCase() === 'done' &&
          mode &&
          ![WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode)
        ) {
          dispatch(setWolfieStatus(WolfieStatus.DONE));
          if (wolfieData && wolfieData.length > 0) {
            dispatch(confirmAllWolfieElements());
          }
        }
        if (parsedContent?.status.toLowerCase() === 'declined') {
          dispatch(setWolfieStatus(WolfieStatus.DECLINED));
        }

        if (
          parsedContent?.status.toLowerCase() === 'done' &&
          mode &&
          [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode)
        ) {
          dispatch(setWolfieStatus(WolfieStatus.DONE));
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
    dispatch(setWolfieLoading(false));
  };

  const sendInitialMessage = (mode: string, contextInfo: ContextInfo) => {
    if (contextInfo && mode === WolfieMode.BLANK_PROCESS) {
      sendMessage([
        generalExplanation,
        emptyProcessInitialMessage,
        generateBlankProcessContextMessage({
          ...contextInfo,
          orgContext: {
            name: organizationName || '',
            description: organizationDescription || '',
            mainAreas: organizationContext?.mainAreas || [],
          },
        }),
      ]);
    }
    if (contextInfo && mode === WolfieMode.BLANK_AREA) {
      sendMessage([
        generalExplanation,
        emptyAreaInitialMessage,
        generateBlankAreaContextMessage({
          ...contextInfo,
          orgContext: {
            name: organizationName || '',
            description: organizationDescription || '',
            mainAreas: organizationContext?.mainAreas || [],
          },
        }),
      ]);
    }
    if (contextInfo && mode === WolfieMode.EXPAND_AREA) {
      sendMessage([
        generalExplanation,
        expandAreaInitialMessage,
        generateExpandAreaContextMessage({
          ...contextInfo,
          orgContext: {
            name: organizationName || '',
            description: organizationDescription || '',
            mainAreas: organizationContext?.mainAreas || [],
          },
        }),
      ]);
    }
    if (contextInfo && mode === WolfieMode.BLANK_SERVICE) {
      sendMessage([
        generalExplanation,
        emptyServiceInitialMessage,
        generateBlankServiceContextMessage({
          ...contextInfo,
          orgContext: {
            name: organizationName || '',
            description: organizationDescription || '',
            mainAreas: organizationContext?.mainAreas || [],
          },
        }),
      ]);
    }
    if (contextInfo && mode === WolfieMode.NO_AREAS) {
      sendMessage([generalExplanation, noAreasInitialMessage, generateNoAreasContextMessage(contextInfo)]);
    }
    if (contextInfo && mode === WolfieMode.FTUE) {
      sendMessage([firstTimeUserInitialMessage, generateFirstTimeContextMessage(contextInfo)]);
    }
    if (contextInfo && mode === WolfieMode.RESET_FTUE) {
      sendMessage([resetFTUEInitialMessage, generateResetFtueContextMessage(contextInfo)]);
    }
    if (contextInfo && mode === WolfieMode.V2_INTERACTION) {
      sendMessage([{ role: 'user', content: 'I want to create a process now.' }]);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setWolfieLoading(true));
    setFileError(null);
    const selectedFile = e.target.files?.[0];

    const maxSizeInBytes = 5 * 1024 * 1024;
    if (selectedFile) {
      if (selectedFile.size > maxSizeInBytes) {
        setFileError('File size exceeds 5MB. Please select a smaller file.');
        return;
      }

      const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();
      const reader = new FileReader();
      reader.onloadend = async () => {
        const result = reader.result;
        if (typeof result !== 'string') {
          return;
        }
        const base64File = result.split(',')[1];
        const payload = {
          processId: currentlyEditingWolfie,
          fileName: selectedFile.name,
          fileType: selectedFile.type,
          fileContent: base64File,
        };

        try {
          const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/uploadAIDocument', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });

          const result = await response.json();

          if (result?.message?.content) {
            const newMessages = [
              ...wolfieMessages.filter((message: any) => message.id !== 'contextInfo'),
              {
                role: 'system',
                content: considerThisFilePrompt + result?.message?.content,
                id: Math.random().toString(36).slice(2, 20),
              },
            ];
            // We parse the response and add it to the conversation
            dispatch(setWolfieLoading(false));
            dispatch(setWolfieMessages(newMessages));
            sendMessage(newMessages);
            dispatch(setWolfieData(result.data));
          }
        } catch (error) {
          dispatch(setWolfieLoading(false));
          console.error('File upload error:', error);
        }
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setFileError('Please upload a file.');
      return;
    }
  };

  const getCurrentLoadingMessage = () => {
    // if (currentStatus === 'researching') {
    //   switch (mode) {
    //     case WolfieMode.FTUE:
    //       if (wolfieMessages.length > 5) {
    //         return localeWolfie['loadingFirstTimeUserInitialized'];
    //       } else {
    //         return localeWolfie['loadingFirstTimeUser'];
    //       }
    //     case WolfieMode.RESET_FTUE:
    //       return localeWolfie['loading'];
    //     case WolfieMode.NO_AREAS:
    //       if (wolfieMessages.length > 5) {
    //         return localeWolfie['loadingNoAreasInitialized'];
    //       } else {
    //         return localeWolfie['loadingNoAreas'];
    //       }
    //     case WolfieMode.BLANK_AREA || WolfieMode.EXPAND_AREA:
    //       if (wolfieMessages.length > 5) {
    //         return localeWolfie['loadingBlankAreaInitialized'];
    //       } else {
    //         return localeWolfie['loadingBlankArea'];
    //       }

    //     case WolfieMode.BLANK_PROCESS:
    //       if (wolfieMessages.length > 5) {
    //         return localeWolfie['loadingBlankProcessInitialized'];
    //       } else {
    //         return localeWolfie['loadingBlankProcess'];
    //       }
    //     case WolfieMode.BLANK_SERVICE:
    //       if (wolfieMessages.length > 5) {
    //         return localeWolfie['loadingBlankServiceInitialized'];
    //       } else {
    //         return localeWolfie['loadingBlankService'];
    //       }
    //     default:
    //       return localeWolfie['loading'];
    //   }
    // }
    return localeWolfie['loading'];
  };

  const handleOptionSelect = (event: any) => {
    const selectedOption = event.target.value;
    setInput(selectedOption);
  };

  if (!wolfieEnabled) {
    return null;
  }

  // if (minimized) {
  //   return (
  //     <Box onClick={open} className="cursor-pointer">
  //       <WolfAvatar size="56px" src={wolfie} />
  //     </Box>
  //   );
  // }

  return (
    <Box className="flex flex-col gap-4 items-end">
      {/* <Box>{wolfieVideoMode && <InteractiveAvatar messages={wolfieMessages} />}</Box> */}
      {!minimized && (
        <Box className="relative">
          <Box
            className="bg-white shadow-md p-4 rounded-lg overflow-y-auto w-full"
            sx={{ width: '450px', height: expanded ? '80vh' : '60vh' }}>
            <Box className="flex items-center gap-2 border-0 border-b-primaryIcy border-b border-solid justify-end absolute top-0 right-0 left-0 h-12 px-4">
              <WolfTooltip title={expanded ? localeWolfie['collapse'] : localeWolfie['expand']}>
                <IconButton onClick={toggleExpand} className="rotate-45">
                  {expanded ? <UnfoldLess /> : <UnfoldMore />}
                </IconButton>
              </WolfTooltip>
              <WolfTooltip title={localeWolfie['minimize']}>
                <IconButton onClick={onClose}>
                  <Minimize />
                </IconButton>
              </WolfTooltip>
            </Box>
            <Box
              className={
                'flex flex-wrap h-auto overflow-y-auto mt-12 ' +
                (expanded ? 'max-h-wolfie-expanded ' : 'max-h-wolfie ') +
                (wolfieLoading ? 'opacity-50' : '')
              }>
              <Box className="flex flex-wrap gap-1 overflow-text-wrap">
                {wolfieMessages
                  .filter((message: any) => message.role !== 'system' && message.id !== 'contextInfo')
                  .map((message: any, index: number) => {
                    let displayMessage = message.content;
                    return (
                      <Box className="w-full h-fit max-h-fit flex" key={index}>
                        <Box className={'flex gap-1 ' + (message.role === 'user' ? 'ml-auto' : '')}>
                          {message.role === 'user' ? (
                            // TODO: Remove if not needed
                            <></>
                          ) : (
                            // <WolfAvatar size="24px" src={avatar || ''} />
                            <></>
                            // <Box
                            //   className="relative flex items-center justify-center w-6 h-6 rounded-full p-4 overflow-hidden"
                            //   sx={{
                            //     backgroundImage: `url(${fuzzyBg})`,
                            //     backgroundSize: 'cover',
                            //     backgroundPosition: 'center',
                            //   }}>
                            //   <img src={wolfieLogo} alt="Wolfie Logo" className="w-4 h-4 relative z-10" />
                            // </Box>
                          )}
                          {message.role === 'user' ? (
                            <Box className="bg-white border-primaryIcy border border-solid p-1 rounded ml-12">
                              <Typography variant="body16" color="primary">
                                {displayMessage}
                              </Typography>
                            </Box>
                          ) : (
                            <Box className="bg-backgroundUI border-primaryIcy border border-solid p-1 rounded mr-12">
                              <Typography variant="body16" color="primary">
                                {displayMessage}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              <Box ref={bottomRef} sx={{ scrollMargin: 100 }} />
            </Box>
            <Box className="absolute bottom-0 flex justify-center w-full h-7 right-0 left-0">
              {/* {wolfieLoading && (
                <Box className="flex justify-end gap-3 w-full mr-5">
                  <CircularProgress size={16} color="inherit" thickness={5} />
                </Box>
              )} */}
            </Box>
            {/* TODO: Suggestions are currently disabled */}
            {/* <Box className="flex gap-2 mt-2 justify-around items-center absolute bottom-28 max-h-7 h-7 left-4 right-4">
          {!wolfieLoading &&
            wolfieSuggestions.length > 0 &&
            wolfieSuggestions.map((suggestion, index) => (
              <WolfButton
                onClick={() => handleSuggestionSelection(suggestion)}
                variant="outlined"
                key={index}
                color="secondary"
                disabled={wolfieLoading}>
                {suggestion}
              </WolfButton>
            ))}
        </Box> */}

            {fileError && <Typography color="error">{fileError}</Typography>}

            <form onSubmit={handleSubmit}>
              <Box className="flex border-t-primaryIcy border-0 border-t border-solid absolute bottom-0 left-0 right-0 h-12">
                <Box className="w-full flex items-center justify-between gap-2 px-2">
                  <WolfInput
                    label=""
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={localeWolfie['messagePlaceholder']}
                    fullWidth
                    autoFocus
                    className="border-0"
                    inputRef={inputRef}
                    keepFocus={true}
                    borderless={true}
                  />
                  {/* <WolfButton
                    type="submit"
                    disabled={wolfieLoading}
                    color="secondary"
                    variant="outlined"
                    endIcon={<Send />}></WolfButton> */}
                </Box>
              </Box>
              {/* TODO: Remove buttons & Re-add attach? */}
              {/* <Box className="flex items-center gap-2 absolute bottom-0 left-0 right-0 bg-primaryFrozen">
            <Box className="p-2 flex items-center gap-2 justify-end">
              <WolfButton type="submit" disabled={wolfieLoading} color="secondary" variant="outlined">
                {localeCommon['send']}
              </WolfButton>
              {(userRole === UserRoles.SUPER_ADMIN || userRole === UserRoles.OWNER) && enabledWolfieVoice && (
                <WolfieMicInput onSubmit={handleSubmitAudio} disabled={wolfieLoading} />
              )}
              {showAttach && (
                <WolfTooltip title={localeCommon['clickToAttachWolfie']}>
                  <span>
                    <WolfButton
                      color="secondary"
                      variant="outlined"
                      component="label"
                      tabIndex={-1}
                      disabled={wolfieLoading}>
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange}
                        accept=".doc,.docx,.txt,.pdf"
                        disabled={wolfieLoading}
                      />
                      <AttachFileOutlined />
                    </WolfButton>
                  </span>
                </WolfTooltip>
              )}
            </Box>
          </Box> */}
              {wolfieData && wolfieData.length > 0 && (
                <Box className="absolute bottom-28 right-4 flex items-center gap-2">
                  <Typography variant="body16">Options:</Typography>
                  <FormControl size="small" sx={{ minWidth: 120 }}>
                    <Select
                      value=""
                      onChange={handleOptionSelect}
                      displayEmpty
                      variant="outlined"
                      sx={{ backgroundColor: 'white' }}>
                      <MenuItem value="" disabled>
                        <em>Select an option</em>
                      </MenuItem>
                      {wolfieData.map((item: any, index: number) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </form>
          </Box>
        </Box>
      )}
      <Box className="">
        <WolfieHub isTalking={wolfieTalking} onSubmit={handleSubmitAudio} />
      </Box>
    </Box>
  );
};

export default Wolfie;
