import WolfButton from '@components/ui/WolfButton';
import WolfTextLink from '@components/ui/WolfTextLink';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { Process } from '@models/process.model';
import { UserRoles } from '@models/user.model';
import { CheckBoxOutlined, CloseOutlined, GroupWorkOutlined } from '@mui/icons-material';
import { Box, Icon, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { RootState } from '@redux/store';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ProcessHeaderDropdown from './ProcessHeaderDropdown';
import { EditIcon24 } from './ui/WolfIcons';
import WolfInput from './ui/WolfInput';

interface ProcessHeaderProps {
  title: string;
  titleLink?: string;
  subTitle: string;
  subTitleLink: string;
  subSubTitle?: string;
  location?: string;
  saving?: boolean;
  editMode: boolean;
  mode: 'edit' | 'view' | 'generate';
  showRemove?: boolean;
  origProcess: Process | undefined;
  onSave: (addNewAfter?: boolean) => void;
  onCancel: () => void;
  onDelete: () => void;
  onProcessDelete: () => void;
  onEdit: () => void;
  handleShowJsonImport: () => void;
  handleShowJsonExport: () => void;
  handleProcessUpdate: (process: Process) => void;
}

const ProcessHeader: React.FC<ProcessHeaderProps> = ({
  title,
  titleLink,
  subTitle,
  subTitleLink,
  subSubTitle,
  location,
  saving,
  editMode,
  mode,
  showRemove = true,
  origProcess,
  onSave,
  onCancel,
  onDelete,
  onProcessDelete,
  onEdit,
  handleShowJsonImport,
  handleShowJsonExport,
  handleProcessUpdate,
}) => {
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');
  const localeProcessForm = useTranslation('processForm');
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { role } = useAppSelector((state: RootState) => state.user);

  const [isHovering, setIsHovering] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [tempTitle, setTempTitle] = useState(subSubTitle);

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    dispatch(appendActionMessage({ message: localeActionMessages['linkCopied'], type: SnackType.DARK }));
  };

  const handleSave = () => {
    onSave(false);
  };

  const handleTitleSubmit = () => {
    if (tempTitle !== subSubTitle && tempTitle !== undefined && origProcess) {
      handleProcessUpdate({
        ...origProcess,
        name: tempTitle,
      });
    }
    setIsEditingTitle(false);
  };

  const handleTempTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempTitle(e.target.value);
  };

  const handleTitleEdit = () => {
    setTempTitle(subSubTitle);
    setIsEditingTitle(true);
  };

  return (
    <Box className="flex justify-between items-center border-b border-primaryIcy p-4">
      <Box
        className="flex items-center relative"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}>
        <Box className="flex items-start w-11/12">
          <Box className="mx-2 my-1 flex">
            <GroupWorkOutlined />
          </Box>
          <Box className="flex items-start flex-wrap">
            {!isEditingTitle && (
              <Typography variant="h3" className="mx-2 cursor-pointer">
                <WolfTextLink
                  variant="h3"
                  component={'span'}
                  className="ml-2 cursor pointer"
                  onClick={() => {
                    history.push('/areas');
                  }}>
                  {localeCommon['areas']}
                </WolfTextLink>
                {title && (
                  <Typography variant="h3" className="ml-2" component={'span'}>
                    {' / '}
                  </Typography>
                )}
                <WolfTextLink
                  variant="h3"
                  component={'span'}
                  className="ml-2 cursor pointer"
                  onClick={() => {
                    history.push(titleLink || '');
                  }}>
                  {title}
                </WolfTextLink>
                {subTitle && (
                  <Typography variant="h3" className="ml-2" component={'span'}>
                    {' / '}
                  </Typography>
                )}{' '}
                <WolfTextLink
                  variant="h3"
                  component={'span'}
                  className="ml-2 cursor pointer"
                  onClick={() => {
                    history.push(subTitleLink || '');
                  }}>
                  {subTitle}
                </WolfTextLink>
                {subSubTitle && (
                  <Typography variant="h3" className="ml-2" component={'span'}>
                    {' / '}
                  </Typography>
                )}
                <Typography variant="h3semibold" className="ml-2 cursor-pointer">
                  {subSubTitle}
                </Typography>
              </Typography>
            )}
            {isEditingTitle && (
              <Box className="flex items-center gap-2 min-w-96 w-96">
                <WolfInput label="" value={tempTitle} onChange={handleTempTitleChange} fullWidth boxFullWidth />
                <Box className="flex items-center gap-2">
                  <Icon
                    onClick={() => {
                      setTempTitle(subSubTitle);
                      setIsEditingTitle(false);
                    }}
                    className="cursor-pointer p-0 text-sm">
                    <CloseOutlined />
                  </Icon>
                  <Icon onClick={handleTitleSubmit} className="cursor-pointer p-0 text-sm">
                    <CheckBoxOutlined />
                  </Icon>
                </Box>
              </Box>
            )}
          </Box>
          {isHovering && role !== UserRoles.USER && !isEditingTitle && (
            <Icon onClick={handleTitleEdit} className="!ml-2 p-0 text-sm absolute right-0">
              <EditIcon24 />
            </Icon>
          )}
        </Box>
      </Box>

      <Box className="flex items-center gap-2">
        {mode !== 'generate' && editMode && (
          <>
            {showRemove && (
              <WolfTextLink
                onClick={onDelete}
                color="error"
                className={`!mr-3 ${saving ? '!text-gray-500 pointer-events-none' : ''}`}>
                {localeProcessForm['removeStep']}
              </WolfTextLink>
            )}
            <WolfButton onClick={onCancel} variant="outlined" color="tertiary">
              {localeCommon['cancel']}
            </WolfButton>
            <WolfButton onClick={handleSave} variant="contained" color="primary" disabled={saving}>
              {localeCommon['save']}
            </WolfButton>
          </>
        )}
        {!editMode && (
          <WolfButton variant="outlined" color="tertiary" onClick={handleShare}>
            {localeCommon['share']}
          </WolfButton>
        )}
        <ProcessHeaderDropdown
          location={location || ''}
          onDelete={onProcessDelete}
          onEdit={onEdit}
          handleShowJsonImport={handleShowJsonImport}
          handleShowJsonExport={handleShowJsonExport}
        />
      </Box>
    </Box>
  );
};

export default ProcessHeader;
