import WolfAvatar from '@components/ui/WolfAvatar';
import WolfButton from '@components/ui/WolfButton';
import { UploadIcon24 } from '@components/ui/WolfIcons';
import { VisuallyHiddenInput } from '@components/VisuallyHiddenInput';
import { useTranslation } from '@hooks/useTranslation';
import { Box } from '@mui/material';

type ProfilePictureProps = {
  src?: string;
  name?: string;
  handleImageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ProfilePicture: React.FC<ProfilePictureProps> = ({ src, name, handleImageChange }) => {
  const localeSettings = useTranslation('settings');

  return (
    <Box className="flex flex-wrap justify-end items-center gap-2">
      <WolfAvatar src={src || ''} alt={name || 'User'} size="104px" />{' '}
      {handleImageChange && (
        <WolfButton
          className="text-wrap"
          component="span"
          role={undefined}
          variant="outlined"
          color="primary"
          tabIndex={-1}
          startIcon={<UploadIcon24 />}>
          {localeSettings['changeAvatar']}
          <VisuallyHiddenInput type="file" accept="image/png, image/jpeg, image/gif" onChange={handleImageChange} />
        </WolfButton>
      )}
    </Box>
  );
};
export default ProfilePicture;
