import CustomModal from '@components/CustomModal';
import DropdownMenu from '@components/DropdownMenu';
import CreateEditUserModal from '@components/modals/CreateEditUserModal';
import DisableUserModal from '@components/modals/DisableUserModal';
import EnableUserModal from '@components/modals/EnableUserModal';
import { EditIcon24 } from '@components/ui/WolfIcons';
import WolfSearch from '@components/ui/WolfSearch';
import WolfTooltip from '@components/ui/WolfTooltip';
import { graphQlClient, updateHeaders } from '@config/graphqlClient';
import UserGraphQL from '@graphql/user.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { Organization } from '@models/organization.model';
import { getSelfWithOrganizationRequest, User, UserLocale, UserRoles, UserStatus } from '@models/user.model';
import { NoAccounts, Person2Outlined, RestartAltOutlined, RestoreFromTrash } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setImpersonate } from '@redux/reducers/impersonate.reducer';
import { setLocale } from '@redux/reducers/locale.reducer';
import { setOrganizationData } from '@redux/reducers/organization.reducer';
import { setUserDetails, updateSidebarStatus } from '@redux/reducers/user.reducer';
import { RootState } from '@redux/store';
import formatDate from '@utils/formatDate';
import { useState } from 'react';

// TODO: With some work this could become generic if we replace documents for a generic type

interface UserListProps {
  handleDisableUser: (userId: string) => void;
  handleEnableUser: (userId: string) => void;
  handleEditUser: (
    organizationId: string,
    status: UserStatus,
    email: string,
    firstName: string,
    lastName: string,
    role: UserRoles,
    userId: string,
  ) => void;
  // common
  count: number;
  users: User[];
  organizations: Organization[];
  // search
  search: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // filter
  filter: string;
  handleFilterChange: (filter: string) => void;
  // order
  orderDirection: 'asc' | 'desc';
  orderBy: string | null;
  handleRequestSort: (property: string) => void;
  // pagination
  page: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resetFailedLoginCount: (userId: string) => void;
}

const UserList: React.FC<UserListProps> = ({
  count,
  users,
  organizations,
  search,
  handleSearchChange,
  filter,
  handleFilterChange,
  orderBy,
  orderDirection,
  handleRequestSort,
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleDisableUser,
  handleEnableUser,
  handleEditUser,
  resetFailedLoginCount,
}) => {
  const [showConfirmDisableModal, setShowConfirmDisableModal] = useState<boolean>(false);
  const [showConfirmEnableModal, setShowConfirmEnableModal] = useState<boolean>(false);
  //TODO: Unify these
  const [userToDisable, setuserToDisable] = useState<User | undefined>(undefined);
  const [userToEdit, setUserToEdit] = useState<User | undefined>(undefined);
  const [userToEnable, setuserToEnable] = useState<User | undefined>(undefined);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');
  const localeUsers = useTranslation('users');
  const dispatch = useAppDispatch();
  const { collapsedSidebar, role } = useAppSelector((state: RootState) => state.user);

  const toggleConfirmDeleteModal = (user?: User) => {
    setuserToDisable(user);
    setShowConfirmDisableModal(true);
  };

  const toggleEnableConfirmModal = (user?: User) => {
    setuserToEnable(user);
    setShowConfirmEnableModal(true);
  };

  const toggleEditModal = (user: User) => {
    setUserToEdit(user);
    setShowEditModal(true);
  };

  const disableUser = () => {
    if (showConfirmDisableModal) {
      handleDisableUser(userToDisable?.userId || '');
    }
    setShowConfirmDisableModal(false);
    setuserToDisable(undefined);
  };

  const enableUser = () => {
    if (showConfirmEnableModal) {
      handleEnableUser(userToEnable?.userId || '');
    }
    setShowConfirmEnableModal(false);
    setuserToEnable(undefined);
  };

  const modalClose = () => {
    setShowConfirmDisableModal(false);
    setShowEditModal(false);
  };

  const handleImpersonate = async (id?: string, name?: string) => {
    try {
      if (!id || !name) return;
      updateHeaders({ Impersonate: `Target ${id}` });
      if (collapsedSidebar) {
        dispatch(updateSidebarStatus());
      }
      dispatch(setImpersonate({ name, id }));

      const userData: getSelfWithOrganizationRequest = await graphQlClient.request(
        UserGraphQL.queries.getSelfWithOrganization,
      );
      dispatch(setOrganizationData(userData.getSelfWithOrganization.organization));
      dispatch(setUserDetails(userData.getSelfWithOrganization.user));
      dispatch(setLocale(UserLocale.EN));
    } catch (e) {
      dispatch(appendActionMessage({ message: localeActionMessages['impersonateFailed'], type: SnackType.ERROR }));
    }
  };

  const sortedTableHeaderCell = ({
    label,
    property,
    align,
  }: {
    label: string;
    property: string;
    align?: 'right' | 'left' | 'center' | 'inherit' | 'justify' | undefined;
  }) => {
    return (
      <TableCell sortDirection={orderBy === property ? orderDirection : false} align={align ?? 'inherit'}>
        <TableSortLabel
          active={orderBy === property}
          direction={orderBy === property ? orderDirection : 'asc'}
          onClick={() => handleRequestSort(property)}>
          {label}
        </TableSortLabel>
      </TableCell>
    );
  };

  const activeFilterItems = [
    { value: 'All', label: localeCommon['all'] },
    { value: 'Active', label: localeCommon['active'] },
    { value: 'Disabled', label: localeCommon['disabled'] },
  ];

  const getOrganizationName = (organizationId: string | undefined) => {
    const organization = organizations.find((t) => t.organizationId === organizationId);
    return organization?.name || '';
  };

  return (
    <>
      <Box>
        <Box className="flex items-center mb-2">
          <WolfSearch value={search} onChange={handleSearchChange} />
          <DropdownMenu items={activeFilterItems} applyFilter={handleFilterChange} filter={filter} />
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {sortedTableHeaderCell({ label: 'Name', property: 'name' })}
                {sortedTableHeaderCell({ label: 'Role', property: 'role' })}
                {sortedTableHeaderCell({ label: 'Email', property: 'email' })}
                {/* // TODO: Sort alphabetically rather rthan by ID */}
                {sortedTableHeaderCell({ label: 'Organization', property: 'organizationId' })}
                {sortedTableHeaderCell({ label: 'Last Login', property: 'lastLogin' })}
                {sortedTableHeaderCell({ label: 'Failed Login Attempts', property: 'failedLoginAttempts' })}
                <TableCell align="center">{localeCommon['impersonate']}</TableCell>
                <TableCell align="center">{localeCommon['actions']}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row, idx) => (
                <TableRow key={`${idx}-${row.firstName}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" title={row.firstName}>
                    {row.firstName &&
                      row.lastName &&
                      (row.firstName + ' ' + row.lastName).slice(0, 35) +
                        ((row.firstName + ' ' + row.lastName).length > 35 ? '...' : '')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.role && row.role.slice(0, 25) + (row.role.length > 25 ? '...' : '')}
                  </TableCell>
                  <TableCell component="th" scope="row" title={row.email}>
                    {row.email && row.email.slice(0, 45) + (row.email.length > 45 ? '...' : '')}
                  </TableCell>
                  <TableCell component="th" scope="row" title={row.email}>
                    {getOrganizationName(row.organizationId) &&
                      getOrganizationName(row.organizationId).slice(0, 25) +
                        (getOrganizationName(row.organizationId).length > 25 ? '...' : '')}
                  </TableCell>
                  <TableCell component="th" scope="row" title={row.email}>
                    {formatDate(row.lastLogin)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {!row?.failedLoginAttempts ? '-' : row?.failedLoginAttempts}
                    {Number(row?.failedLoginAttempts) > 0 && (
                      <WolfTooltip title="Reset failed login attempts">
                        <IconButton onClick={() => resetFailedLoginCount(row?.userId || '')}>
                          <RestartAltOutlined color="error" sx={{ margin: 1 }} />
                        </IconButton>
                      </WolfTooltip>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Box
                      className="flex justify-center items-center"
                      onClick={() => handleImpersonate(row?.userId, row?.firstName + ' ' + row?.lastName)}>
                      <WolfTooltip title={localeCommon['impersonate']}>
                        <IconButton>
                          <Person2Outlined />
                        </IconButton>
                      </WolfTooltip>
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ textAlign: 'center' }} width={140}>
                    <Box className="flex justify-center items-center">
                      {' '}
                      {row.status === UserStatus.ACTIVE && role !== UserRoles.USER && (
                        <>
                          <WolfTooltip title={localeCommon['edit']}>
                            <IconButton onClick={() => toggleEditModal(row)}>
                              <EditIcon24 color="error" sx={{ margin: 1 }} />
                            </IconButton>
                          </WolfTooltip>
                          <WolfTooltip title={localeCommon['disable']}>
                            <IconButton onClick={() => toggleConfirmDeleteModal(row)}>
                              <NoAccounts color="error" sx={{ margin: 1 }} />
                            </IconButton>
                          </WolfTooltip>
                        </>
                      )}
                      {row.status === UserStatus.DISABLED && (
                        <WolfTooltip title={localeCommon['enable']}>
                          <IconButton onClick={() => toggleEnableConfirmModal(row)}>
                            <RestoreFromTrash color="error" sx={{ margin: 1 }} />
                          </IconButton>
                        </WolfTooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <CustomModal
        isOpen={showConfirmDisableModal}
        onClose={modalClose}
        title={localeUsers['disableUser']}
        showButtons={true}
        onConfirm={disableUser}
        customConfirmColor="error"
        maxWidth="600px"
        customConfirmText={localeCommon['disable']}>
        <DisableUserModal user={userToDisable} />
      </CustomModal>

      <CustomModal
        isOpen={showConfirmEnableModal}
        onClose={modalClose}
        showButtons={true}
        onConfirm={enableUser}
        maxWidth="600px"
        title={localeUsers['enableUser']}>
        <EnableUserModal user={userToEnable} />
      </CustomModal>

      <CustomModal isOpen={!!showEditModal} maxWidth="600px" onClose={modalClose} title={localeUsers['editUser']}>
        {/* //TODO adjust saving */}
        <CreateEditUserModal
          editing={true}
          modalClose={modalClose}
          editUser={handleEditUser}
          organizations={organizations}
          saving={false}
          initialData={{
            userId: userToEdit?.userId || '',
            email: userToEdit?.email || '',
            firstName: userToEdit?.firstName || '',
            lastName: userToEdit?.lastName || '',
            role: userToEdit?.role || UserRoles.USER,
            locale: userToEdit?.locale || UserLocale.EN,
          }}
        />
      </CustomModal>
    </>
  );
};

export default UserList;
