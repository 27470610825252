import ActionMessages from '@components/ActionMessages';
import Wolfie from '@components/ai/Wolfie';
import Sidebar from '@components/Sidebar';
import { CssBaseline, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';

const ContentContainer = styled(Box)(({ theme }) => ({
  marginLeft: 240,
  padding: theme.spacing(3),
}));

const CollapsedContentContainer = styled(ContentContainer)(({ theme }) => ({
  marginLeft: 70,
}));

export default function Layout({ children }: { children: React.ReactNode }) {
  const { permanentlyDisabled } = useAppSelector((state: RootState) => state.wolfie);
  const { collapsedSidebar } = useAppSelector((state: RootState) => state.user);
  const enabledWolfie = process.env.REACT_APP_ENABLE_WOLFIE === 'true' && !permanentlyDisabled;

  return (
    <Box display="flex">
      <ActionMessages />
      <CssBaseline />

      <Sidebar />
      {collapsedSidebar ? (
        <CollapsedContentContainer component="main" className="flex flex-grow bg-backgroundUI min-h-screen p-8 h-full">
          {children}
        </CollapsedContentContainer>
      ) : (
        <ContentContainer component="main" className="flex flex-grow bg-backgroundUI min-h-screen p-8 h-full">
          {children}
        </ContentContainer>
      )}
      <Box className="fixed right-10 bottom-10 z-50">{enabledWolfie && <Wolfie />} </Box>
    </Box>
  );
}
