import CustomModal from '@components/CustomModal';
import DropdownMenu from '@components/DropdownMenu';
import CreateEditOrganizationModal from '@components/modals/CreateEditOrganizationModal';
import DisableOrganizationModal from '@components/modals/DisableOrganizationModal';
import EnableOrganizationModal from '@components/modals/EnableOrganizationModal';
import UserInfoModal from '@components/modals/UserInfoModal';
import SplitListCell from '@components/SplitListCell';
import { EditIcon24 } from '@components/ui/WolfIcons';
import WolfSearch from '@components/ui/WolfSearch';
import WolfTooltip from '@components/ui/WolfTooltip';
import { useTranslation } from '@hooks/useTranslation';
import { Organization, OrganizationStatus } from '@models/organization.model';
import { UserRoles } from '@models/user.model';
import { NoAccounts } from '@mui/icons-material';
import RestoreIcon from '@mui/icons-material/Restore';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// TODO: With some work this could become generic if we replace documents for a generic type

interface OrganizationsListProps {
  // deleteDocument
  handleDisableOrganization: (organizationId: string) => void;
  handleEnableOrganization: (organizationId: string) => void;
  handleEditOrganization: (
    name: string,
    email: string,
    firstName: string,
    lastName: string,
    organizationId: string,
  ) => void;
  // common
  count: number;
  organizations: Organization[];
  // search
  search: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // filter
  filter: string;
  handleFilterChange: (filter: string) => void;
  // order
  orderDirection: 'asc' | 'desc';
  orderBy: string | null;
  handleRequestSort: (property: string) => void;
  // pagination
  page: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OrganizationsList: React.FC<OrganizationsListProps> = ({
  count,
  organizations,
  search,
  handleSearchChange,
  filter,
  handleFilterChange,
  orderBy,
  orderDirection,
  handleRequestSort,
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleEnableOrganization,
  handleDisableOrganization,
  handleEditOrganization,
}) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showConfirmEnableModal, setShowConfirmEnableModal] = useState<boolean>(false);
  const [showUserInfoModal, setShowUserInfoModal] = useState<boolean>(false);
  // TODO: With some work this could become generic
  const [organizationToDelete, setOrganizationToDelete] = useState<Organization | undefined>(undefined);
  const [organizationToEdit, setOrganizationToEdit] = useState<Organization | undefined>(undefined);
  const [organizationToEnable, setOrganizationToEnable] = useState<Organization | undefined>(undefined);
  const [userToDetail, setUserToDetail] = useState<any | undefined>(undefined);

  const localeCommon = useTranslation('common');
  const localeOrganizations = useTranslation('organizations');
  const history = useHistory();

  const { role } = useAppSelector((state: RootState) => state.user);

  const toggleConfirmDeleteModal = (organization?: Organization) => {
    setShowConfirmDeleteModal(true);
    setOrganizationToDelete(organization);
  };

  const toggleEnableConfirmModal = (organization?: Organization) => {
    setOrganizationToEnable(organization);
    setShowConfirmEnableModal(true);
  };

  const disableOrganization = () => {
    if (showConfirmDeleteModal) {
      handleDisableOrganization(organizationToDelete?.organizationId || '');
    }
    setShowConfirmDeleteModal(false);
  };

  const toggleEditModal = (organization: Organization) => {
    setOrganizationToEdit(organization);
    setShowEditModal(true);
  };

  const enableOrganization = () => {
    if (showConfirmEnableModal) {
      handleEnableOrganization(organizationToEnable?.organizationId || '');
    }
    setShowConfirmEnableModal(false);
    setOrganizationToEnable(undefined);
  };

  const modalClose = () => {
    setShowConfirmDeleteModal(false);
    setShowEditModal(false);
    setShowConfirmEnableModal(false);
    setShowUserInfoModal(false);
    setUserToDetail(undefined);
    setOrganizationToDelete(undefined);
    setOrganizationToEdit(undefined);
  };

  const showUserInfo = (email: string, organization: string) => {
    setShowUserInfoModal(true);
    setUserToDetail({ email, organization });
  };

  const handleShowOrganizationData = (id: string) => {
    history.push(`/organization/${id}`);
  };

  const sortedTableHeaderCell = ({
    label,
    property,
    align,
  }: {
    label: string;
    property: string;
    align?: 'right' | 'left' | 'center' | 'inherit' | 'justify' | undefined;
  }) => {
    return (
      <TableCell sortDirection={orderBy === property ? orderDirection : false} align={align ?? 'inherit'}>
        <TableSortLabel
          active={orderBy === property}
          direction={orderBy === property ? orderDirection : 'asc'}
          onClick={() => handleRequestSort(property)}>
          {label}
        </TableSortLabel>
      </TableCell>
    );
  };

  const activeFilterItems = [
    { value: 'All', label: localeCommon['all'] },
    { value: 'Active', label: localeCommon['active'] },
    { value: 'Disabled', label: localeCommon['disabled'] },
  ];

  return (
    <>
      <Box>
        <Box className="flex items-center mb-2">
          <WolfSearch value={search} onChange={handleSearchChange} />
          <DropdownMenu items={activeFilterItems} applyFilter={handleFilterChange} filter={filter} />
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {sortedTableHeaderCell({ label: 'Name', property: 'name' })}
                <TableCell>{localeOrganizations['ownersEmails']}</TableCell>
                <TableCell>{localeOrganizations['ownersNames']}</TableCell>
                <TableCell align="center">{localeCommon['actions']}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations.map((row, idx) => (
                <TableRow key={`${idx}-${row.name}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell
                    component="th"
                    scope="row"
                    title={row.name}
                    onClick={() => handleShowOrganizationData(row.organizationId)}>
                    <WolfTooltip title={localeOrganizations['showOrganizationData']}>
                      <span className="cursor-pointer">
                        <Typography>
                          {row.name && row.name.slice(0, 25) + (row.name.length > 25 ? '...' : '')}
                        </Typography>
                      </span>
                    </WolfTooltip>
                  </TableCell>
                  <TableCell component="th" scope="row" className="cursor-pointer text-teal-600">
                    <SplitListCell
                      list={row.ownersEmails || ''}
                      showDetail={(email) => showUserInfo(email, row.organizationId)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" className="cursor-pointer">
                    <SplitListCell list={row.ownersNames || ''} />
                  </TableCell>

                  <TableCell align="right" sx={{ textAlign: 'center' }} width={140}>
                    {
                      <Box className="flex justify-center">
                        {row.status === OrganizationStatus.ACTIVE && role !== UserRoles.USER && (
                          <>
                            <WolfTooltip title={localeCommon['edit']}>
                              <IconButton onClick={() => toggleEditModal(row)}>
                                <EditIcon24 color="error" sx={{ margin: 1 }} />
                              </IconButton>
                            </WolfTooltip>
                            <WolfTooltip title={localeCommon['disable']}>
                              <IconButton onClick={() => toggleConfirmDeleteModal(row)}>
                                <NoAccounts color="error" sx={{ margin: 1 }} />
                              </IconButton>
                            </WolfTooltip>
                          </>
                        )}
                        {row.status === OrganizationStatus.DISABLED && (
                          <WolfTooltip title={localeCommon['enable']}>
                            <IconButton onClick={() => toggleEnableConfirmModal(row)}>
                              <RestoreIcon color="error" sx={{ margin: 1 }} />
                            </IconButton>
                          </WolfTooltip>
                        )}
                      </Box>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <CustomModal
        isOpen={showConfirmDeleteModal}
        onClose={modalClose}
        onConfirm={disableOrganization}
        showButtons={true}
        title={localeOrganizations['disableOrganization']}
        customConfirmColor="error"
        maxWidth="600px"
        customConfirmText={localeCommon['disable']}>
        <DisableOrganizationModal organization={organizationToDelete} />
      </CustomModal>

      <CustomModal
        isOpen={showConfirmEnableModal}
        onClose={modalClose}
        onConfirm={enableOrganization}
        showButtons={true}
        maxWidth="600px"
        title={localeOrganizations['enableOrganization']}
        customConfirmText={localeCommon['enable']}>
        <EnableOrganizationModal organization={organizationToEnable} />
      </CustomModal>

      <CustomModal
        isOpen={showUserInfoModal}
        onClose={modalClose}
        title={localeOrganizations['userInfo']}
        customCancelText={localeCommon['close']}
        maxWidth="600px"
        showButtons={true}
        showConfirmButton={false}>
        <UserInfoModal userToShow={userToDetail?.email} organization={userToDetail?.organization} />
      </CustomModal>

      <CustomModal
        isOpen={showEditModal}
        onClose={modalClose}
        maxWidth="600px"
        title={localeOrganizations['editOrganization']}>
        {/* //TODO adjust saving */}
        <CreateEditOrganizationModal
          editing={true}
          modalClose={modalClose}
          editOrganization={handleEditOrganization}
          saving={false}
          initialData={{
            organizationId: organizationToEdit?.organizationId || '',
            description: organizationToEdit?.description || '',
            name: organizationToEdit?.name || '',
          }}
        />
      </CustomModal>
    </>
  );
};

export default OrganizationsList;
