import logo from '@assets/logos/mrwolf-black-completo-160@2x.png';
import smallLogo from '@assets/logos/mrwolf-black-simple-160@2x.png';

import AvatarDropdown from '@components/AvatarDropdown';
import { AreaIcon24, LeftPanelCloseIcon24, LeftPanelOpenIcon24, ServicesIcon24 } from '@components/ui/WolfIcons';
import SidebarButton from '@components/ui/WolfSidebarButton';
import WolfSearchModal from '@components/WolfSearchModal';
import { getCurrentHeaders, graphQlClient, updateHeaders } from '@config/graphqlClient';
import OrganizationsGraphQL from '@graphql/organization.queries';
import UserGraphQL from '@graphql/user.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { createOrUpdateOrganizationRequest } from '@models/organization.model';
import { getSelfWithOrganizationRequest, UserLocale, UserRoles } from '@models/user.model';
import {
  AccountTreeOutlined,
  AttachFileOutlined,
  CorporateFareOutlined,
  GroupWorkOutlined,
  HubOutlined,
  PeopleAltOutlined,
  PolicyOutlined,
  Search,
} from '@mui/icons-material';
import { Box, IconButton, List, styled, Typography } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { clearImpersonate } from '@redux/reducers/impersonate.reducer';
import { setLocale } from '@redux/reducers/locale.reducer';
import { setOrganizationData } from '@redux/reducers/organization.reducer';
import { logoutUser, setUserDetails, updateSidebarStatus } from '@redux/reducers/user.reducer';
import { RootState } from '@redux/store';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CollapsibleProgress from './CollapsibleProgress';
import WolfButton from './ui/WolfButton';
import WolfTooltip from './ui/WolfTooltip';

const SidebarContainer = styled(Box)(({ theme }) => ({
  width: 240,
  minWidth: 240,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  borderRight: `1px solid ${theme.palette.primaryIcy.main}`,
  backgroundColor: theme.palette.background.default,
  zIndex: 1000,
  overflowY: 'auto',
}));

const CollapsedSidebarContainer = styled(SidebarContainer)(({ theme }) => ({
  width: 70,
  minWidth: 70,
  overflowX: 'hidden',
}));

const Logo = styled('img')(({ theme }) => ({
  height: 40,
  margin: theme.spacing(2, 0),
}));

const MenuItemText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(1),
}));

export interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  const localeMenu = useTranslation('menu');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { avatar, firstName, lastName, role, collapsedSidebar } = useAppSelector((state: RootState) => state.user);
  const { isImpersonating, impersonateName, impersonateId } = useAppSelector((state: RootState) => state.impersonate);
  const { organizationId, onboardingDetails } = useAppSelector((state: RootState) => state.organization);

  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const menuItems = [
    { text: localeMenu['areas'], icon: <AreaIcon24 />, link: '/areas' },
    { text: localeMenu['services'], icon: <ServicesIcon24 />, link: '/services' },
    { text: localeMenu['processes'], icon: <GroupWorkOutlined />, link: '/processes' },
    { text: localeMenu['policies'], icon: <PolicyOutlined />, link: '/policies' },
    { text: localeMenu['documents'], icon: <AttachFileOutlined />, link: '/documents' },
    { text: localeMenu['processMap'], icon: <AccountTreeOutlined />, link: '/processMap' },
  ];

  const onboardingTasks = [
    { order: 1, label: localeMenu['ftueCompleted'], completed: onboardingDetails?.ftueCompleted || false },
    { order: 2, label: localeMenu['areaCreated'], completed: onboardingDetails?.areaCreated || false },
    { order: 3, label: localeMenu['serviceCreated'], completed: onboardingDetails?.serviceCreated || false },
    { order: 4, label: localeMenu['processCreated'], completed: onboardingDetails?.processCreated || false },
    { order: 5, label: localeMenu['processWritten'], completed: onboardingDetails?.processWritten || false },
  ];

  if ([UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.ADMIN].includes(role as UserRoles)) {
    menuItems.push({ text: localeMenu['users'], icon: <PeopleAltOutlined />, link: `/users` });
    menuItems.push({ text: localeMenu['integrations'], icon: <HubOutlined />, link: `/integrations` });
  }

  if ([UserRoles.SUPER_ADMIN].includes(role as UserRoles)) {
    menuItems.push({
      text: localeMenu['organizations'],
      icon: <CorporateFareOutlined />,
      link: `/organizations`,
    });
  }

  useEffect(() => {
    const currentPath = location.pathname || '';
    const cleanedPath = currentPath.replace(/\/$/, ''); // remove ending slash

    const matchedItem = menuItems.find((item) => cleanedPath.startsWith(item.link));
    if (matchedItem) {
      setSelectedItem(matchedItem.text);
    }
  }, [location.pathname]);

  useEffect(() => {
    const headers = getCurrentHeaders();
    // On component mount, fetch the impersonate header from localStorage to resume if it exists, when reloading, etc
    if (impersonateId && !headers.Impersonate) {
      updateHeaders({ Impersonate: `Target ${impersonateId}` });
    }
  }, []);

  const handleMenuItemClick = (link: string, text: string) => {
    setSelectedItem(text);
    history.push(link);
  };

  const toggleCollapse = () => {
    if (isImpersonating) {
      dispatch(
        appendActionMessage({
          message: localeMenu['stopImpersonating'],
          type: SnackType.ERROR,
        }),
      );
    } else {
      dispatch(updateSidebarStatus());
    }
  };

  const openSearch = () => {
    setShowSearch(true);
  };

  const handleStopImpersonate = async () => {
    try {
      dispatch(clearImpersonate());
      history.replace({ pathname: window.location.pathname, search: '' });
      updateHeaders({});
      const userData: getSelfWithOrganizationRequest = await graphQlClient.request(
        UserGraphQL.queries.getSelfWithOrganization,
      );
      dispatch(setOrganizationData(userData.getSelfWithOrganization.organization));
      dispatch(setUserDetails(userData.getSelfWithOrganization.user));

      dispatch(setLocale(UserLocale.EN));
    } catch (e) {
      dispatch(appendActionMessage({ message: localeActionMessages['impersonateClearFailed'], type: SnackType.ERROR }));
      setTimeout(() => {
        dispatch(logoutUser());
      }, 5000);
    }
  };

  const updateOnboardingDismiss = async (type: 'primary' | 'secondary') => {
    try {
      let onboardingDetails = null;
      if (type === 'primary') {
        onboardingDetails = {
          areaCreated: true,
          processCreated: true,
          processWritten: true,
          serviceCreated: true,
          ftueCompleted: true,
        };
      } else if (type === 'secondary') {
        const twoWeeksLater = new Date();
        twoWeeksLater.setDate(twoWeeksLater.getDate() + 14);
        onboardingDetails = { skippedUntil: twoWeeksLater.toISOString() };
      }
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(
        OrganizationsGraphQL.mutations.updateOrganization,
        {
          organization: {
            organizationId: organizationId || '',
            onboardingDetails,
          },
        },
      );
      if (data?.updateOrganization?.organizationId) {
        dispatch(setOrganizationData(data.updateOrganization));
      }
    } catch {}
  };

  const skippedOld = onboardingDetails?.skippedUntil && new Date(onboardingDetails.skippedUntil) < new Date();
  const onboardingCount = onboardingTasks.filter((task) => task.completed).length;
  const totalOnboardingTasks = onboardingTasks.length;

  return (
    <>
      {collapsedSidebar && !isImpersonating ? (
        <CollapsedSidebarContainer>
          <Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Logo src={smallLogo} alt="Mr. Wolf" />
            </Box>
            <Box className="lg:my-6 p-8 flex items-center !h-14 justify-center cursor-pointer" onClick={openSearch}>
              <WolfTooltip title={localeMenu['searchTooltip']}>
                <span>
                  <Search />
                </span>
              </WolfTooltip>
            </Box>
            {onboardingCount < totalOnboardingTasks && (
              <Box className="mx-6 my-3">
                <WolfTooltip title={localeMenu['onboardingTasksCompleted']}>
                  <Typography variant="body14semibold">{onboardingCount + '/' + totalOnboardingTasks}</Typography>
                </WolfTooltip>
              </Box>
            )}
            <List>
              {menuItems.map((item) => (
                <SidebarButton
                  key={item.text}
                  icon={item.icon}
                  label={''}
                  tooltip={item.text}
                  selected={selectedItem === item.text}
                  onClick={() => handleMenuItemClick(item.link, item.text)}
                />
              ))}
            </List>
          </Box>
          <Box className="flex flex-wrap items-center justify-center">
            <AvatarDropdown avatar={avatar || ''} />
            <WolfTooltip title={localeMenu['expandTooltip']}>
              <IconButton onClick={toggleCollapse}>
                <LeftPanelOpenIcon24 />
              </IconButton>
            </WolfTooltip>
          </Box>
        </CollapsedSidebarContainer>
      ) : (
        <SidebarContainer>
          <Box>
            <Box className="flex flex-wrap items-center justify-center">
              <Logo src={logo} alt="Mr. Wolf" />
              <span className="block text-small w-full text-center">{process.env.REACT_APP_VERSION}</span>
            </Box>
            <Box className="lg:my-6 p-6 flex items-center !h-14 cursor-pointer" onClick={openSearch}>
              <WolfTooltip title={localeMenu['searchTooltip']}>
                <span>
                  <Search />
                </span>
              </WolfTooltip>
              <Typography className="!ml-2" variant="body14">
                {localeCommon['search']}
              </Typography>
            </Box>
            {(!onboardingDetails?.skippedUntil || skippedOld) && (
              <CollapsibleProgress
                tasks={onboardingTasks}
                title={localeMenu['onboarding']}
                dismissTitle={localeMenu['dismissPermanently']}
                dismissTitleSecondary={localeMenu['dismissForTwoWeeks']}
                modalTitle={localeMenu['dismissOnboarding']}
                modalText={localeMenu['dismissOnboardingText']}
                handleDismiss={updateOnboardingDismiss}
              />
            )}
            <List className="flex flex-col items-start">
              {menuItems.map((item) => (
                <SidebarButton
                  key={item.text}
                  icon={item.icon}
                  label={item.text}
                  selected={selectedItem === item.text}
                  onClick={() => handleMenuItemClick(item.link, item.text)}
                />
              ))}
            </List>
          </Box>

          {isImpersonating && (
            <Box className="flex flex-wrap text-center justify-center">
              <Typography color="error" variant="h3semibold">
                {localeMenu['workingAs']} {impersonateName}
              </Typography>
              <WolfButton variant="contained" color="secondary" onClick={handleStopImpersonate}>
                {localeMenu['stopImpersonate']}
              </WolfButton>
            </Box>
          )}

          <Box className="flex items-center justify-between">
            <AvatarDropdown avatar={avatar || ''} />
            <WolfTooltip title={firstName + ' ' + lastName}>
              <Typography variant="body14" color="textSecondary" textOverflow={'ellipsis'} component="div">
                {(firstName + ' ' + lastName).slice(0, 20) + ((firstName + ' ' + lastName).length > 20 ? '...' : '')}
              </Typography>
            </WolfTooltip>
            <WolfTooltip title={localeMenu['collapseTooltip']}>
              <IconButton onClick={toggleCollapse}>
                <LeftPanelCloseIcon24 />
              </IconButton>
            </WolfTooltip>
          </Box>
        </SidebarContainer>
      )}

      {<WolfSearchModal open={showSearch} onClose={() => setShowSearch(false)} />}
    </>
  );
};

export default Sidebar;
