import AddItemForm from '@components/drawers/AddItemForm';
import CustomDrawer from '@components/drawers/CustomDrawer';
import GridListSubheader from '@components/GridListSubheader';
import ItemWithSummaryDrag from '@components/ItemWithSummaryDrag';
import WolfHelperNavigation from '@components/ui/WolfHelperNavigation';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import IntegrationsGraphQL from '@graphql/integration.queries';
import OrganizationsGraphQL from '@graphql/organization.queries';
import ServiceGraphQL from '@graphql/service.queries';
import { useLoader } from '@hooks/useLoader';
import { useLocationQuery } from '@hooks/useLocationQuery';
import { useTranslation } from '@hooks/useTranslation';
import useUnsavedChangesWarning from '@hooks/useUnsavedChangesWarning';
import { Area, getAreaByIDRequest, getAreasRequest } from '@models/area.model';
import { DrawerType, EntityData, SnackType } from '@models/common.model';
import { createOrUpdateOrganizationRequest } from '@models/organization.model';
import {
  createOrUpdateServiceRequest,
  deleteServiceRequest,
  getServicesRequest,
  reorderServicesRequest,
  Service,
} from '@models/service.model';
import { UserRoles } from '@models/user.model';
import { WolfieMode, WolfieStatus } from '@models/wolfie.model';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setOrganizationData } from '@redux/reducers/organization.reducer';
import {
  clearConfirmedWolfieElements,
  removeFromWolfieConfirmedElements,
  removeFromWolfieData,
  setWolfieContextInfo,
  setWolfieEnabled,
  setWolfieMinimized,
  setWolfieStatus,
  setWolfieUnableToConfirmElement,
} from '@redux/reducers/wolfie.reducer';
import { RootState } from '@redux/store';
import { checkForIntegrations } from '@utils/checkForIntegrations';
import generateColorVariations from '@utils/generateColorVariations';
import { notValidDrop } from '@utils/notValidDrop';
import { useEffect, useRef, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';

const ServiceList: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { setLoading } = useLoader();

  const localeServices = useTranslation('services');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');

  const [areas, setAreas] = useState<Area[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [selectedArea, setSelectedArea] = useState<Area>();
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState<DrawerType>(DrawerType.ADD);
  const [entityData, setEntityData] = useState<EntityData>({
    areaId: '',
    name: '',
    description: '',
    color: '',
  });
  const [hasCalendar, setHasCalendar] = useState(false);
  const [hasStorage, setHasStorage] = useState(false);
  const [saving, setSaving] = useState(false);
  const query = useLocationQuery();
  const [isGridView, setIsGridView] = useState(true);
  const cardRefs = useRef<{ [key: string]: any | null }>({});
  const [selectedNavigationItem, setSelectedNavigationItem] = useState<any>();
  const [isGenerating, setIsGenerating] = useState(false);
  useUnsavedChangesWarning({ showWarning: isGenerating });

  const {
    data: wolfieData,
    status: wolfieStatus,
    confirmedElements,
    permanentlyDisabled,
    enabled: softEnabledWolfie,
    loading: wolfieLoading,
    mode: wolfieSelectedMode,
  } = useAppSelector((state: RootState) => state.wolfie);
  const { role } = useAppSelector((state: RootState) => state.user);
  const { organizationId, onboardingDetails } = useAppSelector((state: RootState) => state.organization);
  const enabledWolfie = process.env.REACT_APP_ENABLE_WOLFIE === 'true' && !permanentlyDisabled;

  // Get params & get services by areaId or all services if no ID
  useEffect(() => {
    const areaId = query.get('areaId') ?? '';

    if (!areaId || areaId === 'undefined') {
      getServices();
      getAreas();
    } else {
      getServicesByAreaID(areaId);
    }
    getIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (
      wolfieData &&
      softEnabledWolfie &&
      (wolfieSelectedMode === WolfieMode.BLANK_AREA || wolfieSelectedMode === WolfieMode.EXPAND_AREA)
    ) {
      handleSuggestedServices(wolfieData);
    }
  }, [wolfieData, softEnabledWolfie, wolfieSelectedMode]);

  useEffect(() => {
    if (softEnabledWolfie && wolfieStatus === WolfieStatus.RESEARCHING && confirmedElements.length > 0) {
      handleConfirmServiceById(confirmedElements[confirmedElements.length - 1]);
    }
    if (softEnabledWolfie && wolfieStatus === WolfieStatus.DONE && confirmedElements.length > 0 && !wolfieLoading) {
      multipleElementsConfirmWrapper(confirmedElements);
    }
    if ((softEnabledWolfie && wolfieStatus === WolfieStatus.DECLINED) || permanentlyDisabled) {
      setIsGenerating(false);
      setServices(services.filter((service: Service) => !service.isWolfieGenerated));
    }
    if (!softEnabledWolfie) {
      setIsGenerating(false);
    }
  }, [confirmedElements, wolfieStatus, softEnabledWolfie, wolfieLoading, permanentlyDisabled]);

  const handleCardClick = async (id: string) => {
    history.push(`/processes?serviceId=${id}`);
  };

  const handleToggleView = () => {
    setIsGridView(!isGridView);
  };

  // DRAWER HANDLE *********************
  const drawerNewOpen = () => {
    setDrawerType(DrawerType.ADD);
    setShowDrawer(true);
  };

  const drawerEditOpen = (id: string) => {
    setDrawerType(DrawerType.EDIT);
    setShowDrawer(true);
    const serviceToEdit = services.find((service: Service) => service.serviceId === id);
    if (serviceToEdit) {
      setEntityData(serviceToEdit);
    }
  };

  const drawerClose = (): void => {
    setShowDrawer(false);
  };

  // Drag and Drop
  const handleDragEnd = async (result: DropResult) => {
    let newServices: Service[] = [];
    if (notValidDrop(result)) return;
    if (!selectedArea) {
      const sourceAreaId = services.filter((item) => item.serviceId === result.draggableId)[0].areaId;
      newServices = [...services.filter((item) => item.areaId === sourceAreaId)];
    } else {
      newServices = [...services];
    }
    const prev = newServices[result.destination!.index];
    newServices[result.destination!.index] = newServices[result.source.index]; //Checked in notValidDrop
    newServices[result.source.index] = prev;

    const servicesIds = newServices.map((item: Service) => item.serviceId);
    try {
      const data: reorderServicesRequest = await graphQlClient.request(ServiceGraphQL.mutations.reorderServices, {
        services: { servicesIds },
      });
      if (data?.reorderServices.messages[0] === 'Update successful') {
        setServices(newServices);
        dispatch(
          appendActionMessage({
            message: localeActionMessages['servicesReorderedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
      }
    } catch (e: any) {
      console.log('error', e);
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  // CRUD Operations (incl Drawer save new/edit)
  const getServices = async () => {
    try {
      setLoading(true);
      const data: getServicesRequest = await graphQlClient.request(ServiceGraphQL.queries.getServices);
      setServices(data?.getServices.sort((a, b) => (a.order > b.order ? 1 : -1)));
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const getAreas = async () => {
    try {
      const data: getAreasRequest = await graphQlClient.request(AreasGraphQL.queries.getAreas);
      setAreas(data.getAreas.sort((a, b) => (a.order > b.order ? 1 : -1)));
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const getServicesByAreaID = async (id: string) => {
    try {
      setLoading(true);
      const variables = { id };
      const data: getAreaByIDRequest = await graphQlClient.request(AreasGraphQL.queries.getAreaByID, variables);
      setServices(data?.getAreaByID?.services.sort((a, b) => (a.order > b.order ? 1 : -1)));
      setSelectedArea(data?.getAreaByID);
      if (data?.getAreaByID?.services?.length === 0 && enabledWolfie) {
        setIsGenerating(true);
        dispatch(setWolfieEnabled(true));
        dispatch(setWolfieStatus(WolfieStatus.RESEARCHING));
        dispatch(
          setWolfieContextInfo({
            areaContext: { name: data?.getAreaByID.name, description: data?.getAreaByID?.description },
          }),
        );
        dispatch(setWolfieMinimized(false));
      } else if (enabledWolfie) {
        dispatch(setWolfieEnabled(true));
        dispatch(setWolfieStatus(WolfieStatus.RESEARCHING));
        dispatch(
          setWolfieContextInfo({
            areaContext: { name: data?.getAreaByID.name, description: data?.getAreaByID?.description },
            existingServices: data?.getAreaByID?.services,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const deleteResult: deleteServiceRequest = await graphQlClient.request(ServiceGraphQL.mutations.deleteService, {
        id,
      });
      if (deleteResult?.deleteService?.affected > 0) {
        setServices(services.filter((service: Service) => service.serviceId !== id));
        dispatch(appendActionMessage({ message: localeActionMessages['deleteSuccessful'], type: SnackType.SUCCESS }));
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      drawerClose();
    }
  };

  const handleDrawerSave = async (entityData: EntityData) => {
    if (!entityData.name || !entityData.description || !entityData.color) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['nameDescriptionColorRequired'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    const isEdit = drawerType === DrawerType.EDIT;

    if (isEdit && !entityData.areaId) {
      dispatch(
        appendActionMessage({
          message: localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    if (!entityData.name || !entityData.description || !entityData.color) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['fillServiceFields'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    setSaving(true);
    try {
      const query = isEdit ? ServiceGraphQL.mutations.updateService : ServiceGraphQL.mutations.createService;
      const params = {
        service: {
          serviceId: entityData.serviceId,
          areaId: selectedArea?.areaId || entityData.areaId,
          name: entityData.name,
          description: entityData.description,
          color: entityData.color,
        },
      };
      if (!isEdit) {
        delete params?.service?.serviceId;
      }
      const data: createOrUpdateServiceRequest = await graphQlClient.request(query, params);
      if (data?.createService?.serviceId || data?.updateService?.serviceId) {
        if (!onboardingDetails?.serviceCreated) {
          updateOrganizationServiceCreated();
        }
        if (isEdit && data?.updateService) {
          const updatedService: Service = data?.updateService;
          setServices(
            [
              ...services.filter((services: Service) => services.serviceId !== entityData.serviceId),
              updatedService,
            ].sort((a, b) => (a.order > b.order ? 1 : -1)),
          );
        } else {
          setServices([...services, data.createService as Service]);
        }
        drawerClose();
        dispatch(
          appendActionMessage({
            message: isEdit
              ? localeActionMessages['updateSuccessful']
              : localeActionMessages['serviceCreatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        setSaving(false);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const getIntegrations = async () => {
    try {
      const { calendar, storage } = checkForIntegrations(
        await graphQlClient.request(IntegrationsGraphQL.queries.getIntegrations),
      );
      setHasCalendar(calendar);
      setHasStorage(storage);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const servicesInArea = (
    <ItemWithSummaryDrag
      isGridView={isGridView}
      elements={services}
      elementIdName="serviceId"
      showDrag={!!selectedArea}
      hasCalendar={hasCalendar}
      hasStorage={hasStorage}
      droppableId={`cards-${selectedArea?.areaId}`}
      handleDragEnd={handleDragEnd}
      drawerEditOpen={drawerEditOpen}
      handleCardClick={handleCardClick}
      cardsRefs={cardRefs}
    />
  );
  const areaWithServices = areas.sort().map((area: Area) => {
    return (
      <Box key={area.areaId} sx={{ scrollMargin: 100 }} ref={(el) => (cardRefs.current[area.areaId] = el)}>
        <Box className="flex items-center my-8">
          <Typography variant="h4semibold" className="mx-2">
            {area.name}
          </Typography>
        </Box>
        {
          <ItemWithSummaryDrag
            isGridView={isGridView}
            elements={services.filter((service) => service.areaId === area.areaId)}
            elementIdName="serviceId"
            showDrag={true}
            hasCalendar={hasCalendar}
            hasStorage={hasStorage}
            droppableId={area.areaId}
            handleDragEnd={handleDragEnd}
            drawerEditOpen={drawerEditOpen}
            handleCardClick={handleCardClick}
            cardsRefs={cardRefs}
          />
        }
      </Box>
    );
  });

  const allAreasNavigation = areas.sort().map((area: Area) => {
    return (
      <div key={area.areaId}>
        <WolfHelperNavigation
          type={'title'}
          label={area.name}
          selected={selectedNavigationItem === area.areaId}
          onSelect={() => {
            scrollToCard(area.areaId);
          }}
        />
        {services
          .filter((service) => service.area?.areaId === area.areaId)
          .sort()
          .map((service: Service) => {
            return (
              <div key={service.serviceId}>
                <WolfHelperNavigation
                  key={service.serviceId}
                  type={'section'}
                  label={service.name}
                  selected={selectedNavigationItem === service.serviceId}
                  onSelect={() => {
                    scrollToCard(service.serviceId);
                  }}
                />
              </div>
            );
          })}
      </div>
    );
  });

  const selectedNavigation = services.map((service: Service) => {
    return (
      <WolfHelperNavigation
        key={service.serviceId}
        type={'section'}
        label={service.name}
        selected={selectedNavigationItem === service.serviceId}
        onSelect={() => {
          scrollToCard(service.serviceId);
        }}
      />
    );
  });
  const scrollToCard = (id: string) => {
    setSelectedNavigationItem(id);

    if (cardRefs.current[id]) {
      cardRefs.current[id]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleSuggestedServices = (newServices: any[]) => {
    if (!newServices || newServices.length === 0 || !Array.isArray(newServices)) return;
    newServices.forEach((service, idx) => {
      service.description = service.descriptionText || '';
      service.serviceId = service.id;

      service.color = generateColorVariations(selectedArea?.color || '', [])[0];
      service.order = areas.length + 1;
    });
    setServices((prevServices) => [...prevServices.filter((item) => !item.isWolfieGenerated), ...newServices]);
  };

  const multipleElementsConfirmWrapper = async (ids: string[]) => {
    let currentId = '';
    let success = true;
    try {
      for (let id of ids) {
        currentId = id;
        success = (await handleConfirmServiceById(id, ids[0] === id, false)) || false;
        if (!success) {
          break;
        }
      }
      if (!success) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
        dispatch(setWolfieUnableToConfirmElement(true));
        dispatch(clearConfirmedWolfieElements());
      }
      setIsGenerating(false);
    } catch {}
  };

  const handleConfirmServiceById = async (id: string, showSnack: boolean = true, showErrorSnack: boolean = true) => {
    if (!id) return;
    const auxService = services.find((service: Service) => service.serviceId === id);
    if (!auxService) return;
    try {
      const query = ServiceGraphQL.mutations.createService;
      const params: { service: Partial<Service> } = {
        service: {
          name: auxService?.name,
          description: auxService?.description,
          color: auxService?.color,
          areaId: selectedArea?.areaId,
        },
      };
      const data: createOrUpdateServiceRequest = await graphQlClient.request(query, params);
      if (data?.createService?.areaId) {
        if (!onboardingDetails?.serviceCreated) {
          updateOrganizationServiceCreated();
        }
        const position = services.findIndex((service: Service) => service.serviceId === id);
        setServices((prevServ) => [
          ...prevServ.slice(0, position),
          data.createService as Service,
          ...prevServ.slice(position).filter((service: Service) => service.serviceId !== id),
        ]);
        dispatch(removeFromWolfieData(id));
        dispatch(removeFromWolfieConfirmedElements(id));
        if (services.filter((service: Service) => service.isWolfieGenerated).length === 1) {
          setIsGenerating(false);
        }
        if (showSnack) {
          dispatch(
            appendActionMessage({
              message: localeCommon['requestSuccess'],
              type: SnackType.SUCCESS,
            }),
          );
        }
        return true;
      }
    } catch {
      if (showErrorSnack) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
        dispatch(setWolfieUnableToConfirmElement(true));
        dispatch(removeFromWolfieConfirmedElements(id));
      }
    }
    return false;
  };

  const updateOrganizationServiceCreated = async () => {
    try {
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(
        OrganizationsGraphQL.mutations.updateOrganization,
        {
          organization: {
            organizationId: organizationId || '',
            onboardingDetails: {
              ...onboardingDetails,
              serviceCreated: true,
            },
          },
        },
      );
      if (data?.updateOrganization?.organizationId) {
        dispatch(setOrganizationData(data.updateOrganization));
      }
    } catch {}
  };

  return (
    <>
      {/* <Prompt when={isGenerating} message={localeCommon['changesNotSaved']} /> */}
      <Box className="w-full">
        <Box className="sticky top-0 z-30 bg-backgroundUI">
          <GridListSubheader
            title={localeCommon['areas']}
            subTitle={selectedArea?.name || localeCommon['allAreas']}
            subTitleLink="/areas"
            buttonText={role !== UserRoles.USER ? localeServices['addNew'] : null}
            isGridView={isGridView}
            onToggleView={handleToggleView}
            onDrawerOpen={drawerNewOpen}
            icon="area"
            iconColor={selectedArea?.color}
          />
        </Box>
        <Box className="flex">
          <Box className="w-3/4">{selectedArea ? servicesInArea : areaWithServices}</Box>
          <Box className="w-1/4 fixed -right-16 overflow-y-scroll h-5/6">
            {selectedArea ? selectedNavigation : allAreasNavigation}
          </Box>
        </Box>
      </Box>

      <CustomDrawer
        isOpen={showDrawer}
        onClose={drawerClose}
        title={drawerType === 'edit' ? localeServices['edit'] : localeServices['addNew']}>
        <AddItemForm
          onClose={drawerClose}
          handleSave={handleDrawerSave}
          handleDelete={handleDelete}
          drawerType={drawerType}
          initialData={entityData}
          saving={saving}
          hasSelectedArea={!!selectedArea}
          isService={true}
          saveBtnText={drawerType === DrawerType.EDIT ? localeServices['editService'] : localeServices['createService']}
          parentColor={selectedArea?.color || areas.filter((area: Area) => area.areaId === entityData.areaId)[0]?.color}
          existingColors={services
            .filter((service: Service) => service.serviceId !== entityData.serviceId)
            .map((service: Service) => service.color)}></AddItemForm>
      </CustomDrawer>
    </>
  );
};

export default ServiceList;
